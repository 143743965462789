import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridColumnsInitialState,
  GridFilterModel,
} from '@mui/x-data-grid';
import {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '../../assets/svgs/icons/AddIcon';
import { CopyIcon2 } from '../../assets/svgs/icons/CopyIcon2';
import { DeleteIcon } from '../../assets/svgs/icons/DeleteIcon';
import { EditIcon2 } from '../../assets/svgs/icons/EditIcon2';
import { EyeIcon } from '../../assets/svgs/icons/EyeIcon';
import { MenuIcon } from '../../assets/svgs/icons/MenuIcon';
import { BRAND_ORANGE } from '../../constants/colors';
import { AuthContext } from '../../contexts/Auth';
import {
  OrganizationContext,
  OrganizationContextProps,
} from '../../contexts/Organization';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  APISource,
  Application,
  UserContext,
  UserContextProps,
} from '../../contexts/User';
import AlertDialog from '../../dialogs/AlertDialog';
import { useHasFeature } from '../../hooks/useHasFeature';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import {
  PyntFilter,
  deleteApplication,
  getApplications,
  getApplicationsCount,
  updateUserAssignee,
} from '../../services/ApplicationsService';
import { track } from '../../utils/analytics';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import { apiCatalogSourceTitle } from '../APICatalog/APICatalogGrid';
import RiskScoreChip, { normalizeRiskScore } from '../APICatalog/RiskScoreChip';
import PyntDataGrid from '../Common/PyntDataGrid';
import RunScanDrawer from '../RunScanDrawer/RunScanDrawer';
import EditApplicationDrawer from '../Setup/EditApplicationDrawer';
import EditSourceDrawer from '../Setup/EditSource/EditSourceDrawer';
import SourcesGridCell from './SourcesGridCell';

const HOVERABLE_NUMBER: SxProps = {
  'color': 'black',
  'fontWeight': 400,
  'fontSize': 16,
  'p': 1,
  'marginInlineStart': -1,
  'minWidth': 0,
  '&:hover': {
    bgColor: 'transparent',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: '#FF8C00',
  },
};

interface Props {
  id?: string;
  filter?: GridFilterModel;
  sorting?: GridSortModel;
  hideFooter?: boolean;
  pagination?: Partial<GridPaginationModel>;
  columnVisibilityModel?: GridColumnVisibilityModel;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  pyntFilter?: PyntFilter['where'];
}

export default function ApplicationsGrid({
  id = 'ApplicationsGrid',
  filter,
  sorting,
  hideFooter,
  pagination,
  columnVisibilityModel,
  autoHeight = true,
  autoPageSize = false,
  pyntFilter,
}: Props) {
  const navigate = useNavigate();

  const { show } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const { users, fetchUsers } = useContext(
    OrganizationContext,
  ) as OrganizationContextProps;

  const { setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  const hasCreateSourceFeature = useHasFeature('create_source');
  const hasDeleteApplicationFeature = useHasFeature('delete_application');
  const hasEditApplicationFeature = useHasFeature('edit_application');

  const [applications, setApplications] = useState<Application[]>();
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'risk', sort: 'desc' },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState();

  const [isEditApplicationDrawerOpen, setIsEditApplicationDrawerOpen] =
    useState(false);
  const [isEditSrouceDrawerOpen, setIsEditSrouceDrawerOpen] =
    useState<string>();
  const [isRunScanDrawerOpen, setIsRunScanDrawerOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<Application>();
  const [selectedSource, setSelectedSource] = useState<APISource>();
  const [openDeleteApplicationDialog, setOpenDeleteApplicationDialog] =
    useState(false);

  useEffect(() => {
    const storedApplications = localStorage.getItem(`applications-${id}`);
    if (storedApplications) {
      try {
        const parsedApplications = JSON.parse(storedApplications);
        if (!parsedApplications.length) return;
        setApplications(parsedApplications);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchApplications = useCallback(async () => {
    if (isLoadingApplications) return;

    setIsLoadingApplications(true);

    const filter: PyntFilter = {
      where: { ...reformatFilterModel(filterModel, columns), ...pyntFilter },
      sort: reformatSortModel(sortModel ?? [], columns),
      offset: page * pageSize,
      limit: pageSize,
    };

    const applications = await getApplications(filter).catch((e) => {
      console.error(e);
    });

    if (page === 0) {
      if (applications) {
        localStorage.setItem(
          `applications-${id}`,
          JSON.stringify(applications),
        );
      } else {
        localStorage.removeItem(`applications-${id}`);
      }
    }

    const applicationsCount = await getApplicationsCount(filter.where).catch(
      (e) => {
        console.error(e);
      },
    );

    setCount(applicationsCount?.count);

    setApplications(applications);
    setIsLoadingApplications(false);

    return applications;
  }, [
    isLoadingApplications,
    filterModel,
    sortModel,
    page,
    pageSize,
    pyntFilter,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchApplications();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterModel, sortModel, page, pageSize, pyntFilter]);

  const onNewSourceButtonClick = useMemo(() => {
    return (
      app: Application,
      source?: 'documentation' | 'testing' | 'production',
    ) => {
      track('web_app_add_new_source_button_click');

      setSelectedSource(undefined);
      setSelectedApplication(app);
      setIsEditSrouceDrawerOpen(source);
    };
  }, []);

  const onNewTestingSourceButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_add_new_testing_source_button_click');

      setSelectedSource(undefined);
      setSelectedApplication(app);
      setIsRunScanDrawerOpen(true);
    };
  }, []);

  const onEditApplicationButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_edit_application_button_click', {
        appId: app.app_id,
        appName: app.name,
      });

      setSelectedApplication(app);
      setIsEditApplicationDrawerOpen(true);
    };
  }, []);

  const onDeleteApplicationButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_delete_application_button_click', {
        appId: app.app_id,
        appName: app.name,
      });

      setSelectedApplication(app);
      setOpenDeleteApplicationDialog(true);
    };
  }, []);

  const onViewSourceButtonClick = useMemo(() => {
    return (app: Application, source: APISource) => {
      track('web_app_view_source_button_click');

      if (source.type === 'scan') {
        return;
      }

      setSelectedApplication(app);
      setSelectedSource(source);
      setIsEditSrouceDrawerOpen('true');
    };
  }, []);

  const onAppIdCopyButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_copy_application_id_button_click');

      navigator.clipboard.writeText(app.app_id);
      show('Application ID copied to clipboard');
    };
  }, []);

  const onAppViewEndpointsButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_view_app_endpoints_button_click');

      setSelectedApplicationId(app.app_id);
      navigate(`/dashboard/application/${app.app_id}?tab=apis`);
    };
  }, []);

  // const onAppViewTestsButtonClick = useMemo(() => {
  //   return (app: Application) => {
  //     track('web_app_view_app_tests_button_click');

  //     setSelectedApplicationId(app.app_id);
  //     navigate(`/dashboard/application/${app.app_id}?tab=tests`);
  //   };
  // }, []);

  const onAppViewVulnerabilitiesButtonClick = useMemo(() => {
    return (app: Application) => {
      track('web_app_view_app_vulnerabilities_button_click');

      setSelectedApplicationId(app.app_id);
      navigate(`/dashboard/application/${app.app_id}?tab=vulnerabilities`);
    };
  }, []);

  const isAdmin = useMemo(() => user?.role === 'Admin', [user?.role]);

  useEffect(() => {
    if (isAdmin && !users) fetchUsers();
  }, [isAdmin, users]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'risk',
        headerName: 'Risk Score',
        width: 140,
        type: 'singleSelect',
        valueOptions: [
          { value: 4, label: 'CRITICAL' },
          { value: 3, label: 'HIGH' },
          { value: 2, label: 'MEDIUM' },
          { value: 1, label: 'LOW' },
        ],
        renderCell: ({ value }) => (
          <Tooltip
            title={
              <>
                The risk of the application is determined based on the highest
                risk associated API within the application. For more
                information, visit:{' '}
                <a
                  href="https://docs.pynt.io/documentation/api-catalog/navigate-catalog/apis-at-risk"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: BRAND_ORANGE }}
                  onClick={() => {
                    track('web_app_application_risk_calculation_button_click');
                  }}
                >
                  API Risk Calculation
                </a>
                .
              </>
            }
          >
            <Box>
              <RiskScoreChip score={normalizeRiskScore(value) ?? 'N/A'} />
            </Box>
          </Tooltip>
        ),
      } as GridColDef,
      {
        field: 'name',
        headerName: 'App Name',
        filterable: false,
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'productionSources',
        headerName: 'Production',
        width: 120,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => {
          return Object.keys(row.data_sources || {})
            .filter((s) => apiCatalogSourceTitle(s as any) === 'Production')
            .map((s) => {
              return {
                application: row.app_id,
                type: s,
                name: row.data_sources[s]?.name || 'Source',
                source_id: row.data_sources[s]?.source_id || '',
                status: row.data_sources[s]?.status || 'SUCCEEDED',
                properties: {},
              };
            });
        },
        renderCell: ({ value, row }) => (
          <Box ml={-1}>
            <SourcesGridCell
              sources={value || []}
              hideAdd={!hasCreateSourceFeature}
              onAddClick={() => onNewSourceButtonClick(row, 'production')}
              onSourceClick={(source) =>
                onViewSourceButtonClick(row, source as any)
              }
            />
          </Box>
        ),
      },
      {
        field: 'testingSources',
        headerName: 'Testing',
        width: 120,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => {
          const scanSource = row.data_sources?.scan?.source_id;
          if (!scanSource) return [];

          return [
            {
              application: row.app_id,
              type: 'scan',
              name: 'Pynt Scan',
              source_id: scanSource,
              status: 'SUCCEEDED',
              properties: {},
            },
          ];
        },
        renderCell: ({ value, row }) => (
          <Box ml={-1}>
            <SourcesGridCell
              sources={value || []}
              hideAdd={!hasCreateSourceFeature}
              onAddClick={() => onNewTestingSourceButtonClick(row)}
              onSourceClick={(source) =>
                onViewSourceButtonClick(row, source as any)
              }
            />
          </Box>
        ),
      },
      {
        field: 'documentationSources',
        headerName: 'Documentation',
        width: 120,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => {
          return Object.keys(row.data_sources || {})
            .filter((s) => apiCatalogSourceTitle(s as any) === 'Documentation')
            .map((s) => {
              return {
                application: row.app_id,
                type: s,
                name: row.data_sources[s]?.name || 'Source',
                source_id: row.data_sources[s]?.source_id || '',
                status: row.data_sources[s]?.status || 'SUCCEEDED',
                properties: {},
              };
            });
        },
        renderCell: ({ value, row }) => (
          <Box ml={-1}>
            <SourcesGridCell
              sources={value || []}
              hideAdd={!hasCreateSourceFeature}
              onAddClick={() => onNewSourceButtonClick(row, 'documentation')}
              onSourceClick={(source) =>
                onViewSourceButtonClick(row, source as any)
              }
            />
          </Box>
        ),
      },
      {
        field: 'number_of_endpoints',
        headerName: 'Endpoints',
        width: 100,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        valueGetter: ({ value }) => value || 0,
        renderCell: ({ value, row }) => (
          <Button
            sx={HOVERABLE_NUMBER}
            onClick={(e) => {
              e.stopPropagation();
              onAppViewEndpointsButtonClick(row);
            }}
          >
            {`${value}`.padStart(1, '0')}
          </Button>
        ),
      },
      // {
      //   field: 'tests',
      //   headerName: 'Tests',
      //   width: 80,
      //   type: 'number',
      //   align: 'left',
      //   headerAlign: 'left',
      //   valueGetter: ({ value }) => value || 0,
      //   renderCell: ({ value, row }) => (
      //     <Button
      //       sx={HOVERABLE_NUMBER}
      //       onClick={(e) => {
      //         e.stopPropagation();
      //         onAppViewTestsButtonClick(row);
      //       }}
      //     >
      //       {`${value}`.padStart(1, '0')}
      //     </Button>
      //   ),
      // },
      {
        field: 'vulnerabilities',
        headerName: 'Vulnerabilities',
        width: 120,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        valueGetter: ({ value }) => value || 0,
        renderCell: ({ value, row }) => (
          <Button
            sx={HOVERABLE_NUMBER}
            onClick={(e) => {
              e.stopPropagation();
              onAppViewVulnerabilitiesButtonClick(row);
            }}
          >
            {`${value}`.padStart(1, '0')}
          </Button>
        ),
      },
      {
        field: 'last_scan',
        headerName: 'Last Scan',
        width: 170,
        type: 'dateTime',
        valueGetter: ({ value }) => (value ? new Date(value + 'Z') : undefined),
        renderCell: ({ value }: { value?: Date }) =>
          value ? (
            <>
              {value.toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </>
          ) : (
            <></>
          ),
      },
      {
        field: 'assignee_id',
        headerName: 'Assignee',
        width: 160,
        sortable: false,
        filterable: false,
        renderCell: ({ value, row }) => {
          return isAdmin ? (
            <Select
              defaultValue={value}
              onChange={(v) =>
                updateUserAssignee(row.app_id, row.name, v.target.value)
              }
              sx={{ width: '90%', height: 32 }}
            >
              {users?.map((u) => (
                <MenuItem key={u.user_id} value={u.user_id}>
                  {`${u.name} ${u.lastname}`}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <>{row.assignee_name}</>
          );
        },
      },

      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <LineActionsButton
            actions={[
              ...(hasEditApplicationFeature
                ? [
                    {
                      icon: <EditIcon2 />,
                      title: 'Rename',
                      onClick: () => onEditApplicationButtonClick(row),
                    },
                  ]
                : []),
              {
                icon: <EyeIcon />,
                title: 'View',
                onClick: () => {
                  navigate(`/dashboard/application/${row?.app_id}`);
                },
              },
              {
                icon: <CopyIcon2 />,
                title: 'Copy App ID',
                onClick: () => onAppIdCopyButtonClick(row),
              },
              ...(hasCreateSourceFeature
                ? [
                    {
                      icon: <AddIcon />,
                      title: 'Add Source',
                      onClick: () => onNewSourceButtonClick(row),
                    },
                  ]
                : []),
              ...(hasDeleteApplicationFeature
                ? [
                    {
                      icon: <DeleteIcon />,
                      title: 'Delete',
                      onClick: () => onDeleteApplicationButtonClick(row),
                    },
                  ]
                : []),
            ]}
          />
        ),
      },
    ],
    [applications, isAdmin, users],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(`${id}ColumnsState`, {
      columnVisibilityModel: {
        id: false,
        endpoint_id: false,
        pii: false,
        configuration: false,
        security: false,
        scansCount: false,
      },
    });

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      pinnedColumns: {
        right: ['actions'],
      },
      columns: {
        ...gridColumnsState,
        columnVisibilityModel: {
          ...gridColumnsState.columnVisibilityModel,
          ...(columnVisibilityModel ?? {}),
        },
      },
    };
  }, [gridColumnsState, filter, pagination, sorting]);

  return (
    <>
      <EditApplicationDrawer
        mode={selectedApplication ? 'edit' : 'create'}
        application={selectedApplication}
        isOpen={isEditApplicationDrawerOpen}
        setIsOpen={setIsEditApplicationDrawerOpen}
      />
      <EditSourceDrawer
        mode={selectedApplication && selectedSource ? 'view' : 'create'}
        application={selectedApplication}
        defaultData={
          selectedSource?.source_id
            ? undefined
            : { sourceOrigin: isEditSrouceDrawerOpen as any }
        }
        sourceId={selectedSource?.source_id}
        isOpen={!!isEditSrouceDrawerOpen}
        setIsOpen={() => setIsEditSrouceDrawerOpen(undefined)}
      />
      <RunScanDrawer
        application={selectedApplication}
        hideAppSelector
        isOpen={isRunScanDrawerOpen}
        setIsOpen={() => setIsRunScanDrawerOpen(false)}
      />
      <AlertDialog
        key={'delete-application'}
        open={openDeleteApplicationDialog}
        onClose={() => {
          setOpenDeleteApplicationDialog(false);
        }}
        title="Do you wish to continue deleting this application?"
        actions={[
          {
            // eslint-disable-next-line quotes
            title: "No, I'd like to keep it",
            onClick: () => setOpenDeleteApplicationDialog(false),
          },
          {
            title: 'Yes, Delete the application',
            onClick: () => {
              const selectedApplicationId = selectedApplication?.app_id;
              setOpenDeleteApplicationDialog(false);
              show('Deleting application...');
              deleteApplication(selectedApplicationId || '')
                .then(() => fetchApplications())
                .then(() => {
                  show('Application deleted.');
                })
                .catch((e) => {
                  show(
                    'Failed to delete application, ' + e.message ||
                      e.toString(),
                    'error',
                  );
                });
            },
          },
        ]}
      />
      <PyntDataGrid
        paginationMode="server"
        filterMode="server"
        sortingMode={'server'}
        sortModel={sortModel}
        filterModel={filterModel}
        onSortModelChange={(sortModel) => setSortModel([...sortModel])}
        onFilterModelChange={(filterModel) =>
          setFilterModel({ ...filterModel })
        }
        paginationModel={{ page: page || 0, pageSize: pageSize || 50 }}
        onPaginationModelChange={(paginationModel) => {
          setPage(paginationModel.page);
          setPageSize(paginationModel.pageSize);
        }}
        rowCount={count || 0}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        sx={{ width: '100%', borderRadius: 1 }}
        rows={applications ?? []}
        loading={!applications || isLoadingApplications}
        columns={columns}
        getRowId={(row) => row.app_id}
        initialState={gridInitialState}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        hideFooter={hideFooter}
        onRowClick={({ row }) => {
          // allow to select text without interaption
          if (window.getSelection()?.type === 'Range') return;

          navigate(`/dashboard/application/${row?.app_id}`);
        }}
      />
    </>
  );
}

const LineActionsButton = ({
  actions,
}: {
  actions: { icon: ReactElement; title: string; onClick: () => void }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        id="actions-button"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.title}
            onClick={() => {
              action.onClick();
              setAnchorEl(null);
            }}
          >
            {action.icon}
            <Typography mx={1}>{action.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
