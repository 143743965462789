import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { createContext, useCallback, useEffect, useMemo } from 'react';
// import { useIntercom } from 'react-use-intercom';
import { usePersistedJsonState } from '../hooks/usePersistedState';
import { User } from '../models/user';
import {
  acceptInvite,
  exchangeOAuthToken,
  getUser,
  putUser,
} from '../services/AuthService';
import {
  getStoredHeaders,
  onRefreshError,
  storeAccessToken,
  storeHeaders,
  storeRefreshToken,
} from '../services/BaseService';
import { analyticsIdentify, track, trackOptions } from '../utils/analytics';
import { updateHotjar } from '../utils/hotjar';
interface AuthContextProps {
  user?: User;
  isAuthenticated: boolean;
  isProd: boolean;
  license?: 'trial';
  planExpirationInDays: number;
  refreshUser: () => void;
  setIsProd: (_: boolean) => void;
  logout: () => void;
  loginWithGoogle: () => void;
  loginWithGitHub: () => void;
  loginWithLinkedIn: () => void;
  loginWithAzure: () => void;
  confirmOAuthCode: (code: string, state?: string) => Promise<any>;
}
export const AuthContext = createContext<AuthContextProps>({
  isProd: true,
  planExpirationInDays: 21,
  refreshUser: () => {},
  setIsProd: (_) => {},
  logout: () => {},
  loginWithGoogle: () => {},
  loginWithGitHub: () => {},
  loginWithLinkedIn: () => {},
  loginWithAzure: () => {},
  confirmOAuthCode: async () => {},
  isAuthenticated: false,
});
function AuthContextProvider(props: any) {
  // const { trackEvent, boot: bootIntercom } = useIntercom();
  const [user, setUser] = usePersistedJsonState<User | undefined>('user');
  const [isAuthenticated, setIsAuthenticated] =
    usePersistedJsonState<boolean>('isAuthenticated');
  const [isProd, setIsProd] = usePersistedJsonState(
    '#$%#$#$',
    process.env.REACT_APP_NODE_ENV !== 'development',
    sessionStorage,
  );
  const license = useMemo(() => user?.license?.license_type, [user]);
  const planExpirationInDays = useMemo(
    () =>
      user?.license?.start_date && user?.license?.expiration
        ? dayjs(user?.license?.expiration).diff(new Date(), 'day')
        : 14 || 14,
    [user],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const xOrg = searchParams.get('x-org');
    if (xOrg) {
      storeHeaders({ ...getStoredHeaders(), 'X-Org': xOrg });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    refreshUser();
  }, [isAuthenticated]);

  useEffect(() => {
    onRefreshError.cb = () => {
      logout();
    };
    return () => {
      onRefreshError.cb = undefined;
    };
  }, [isAuthenticated]);

  // useEffect(() => {
  //   trackOptions.intercomTrack = trackEvent;
  // }, [trackEvent]);

  useEffect(() => {
    trackOptions.globalParamters.organizationId = user?.organization;
    if (!isAuthenticated || !user) {
      // bootIntercom();
      return;
    }
    analyticsIdentify(user.user_id, user.email, user);
    updateHotjar(user);
    // bootIntercom({
    //   email: user.email,
    //   name: user.name,
    //   userId: user.user_id,
    //   company: user.organization
    //     ? {
    //         companyId: user.organization,
    //         name: user.organization_name,
    //       }
    //     : undefined,
    // });
  }, [
    isAuthenticated,
    user,
    // bootIntercom,
  ]);
  const getOAuthRedirectUri = () => {
    const redirectUri = window.location.origin + '/auth';
    return redirectUri;
  };
  const buildOAuthUrl = (
    provider: 'Google' | 'GithubShim' | 'LinkedIn' | 'Azure',
  ) => {
    const redirectUri = encodeURIComponent(getOAuthRedirectUri());
    const params = new URLSearchParams(window.location.search);
    const state: any = {};
    if (params.has('invite')) state.inviteId = params.get('invite');
    if (params.has('goto')) state.goto = params.get('goto');
    return `${
      process.env.REACT_APP_OAUTH_BASE_URL
    }/oauth2/authorize?identity_provider=${provider}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${
      process.env.REACT_APP_OAUTH_CLIENT_ID
    }&scope=pynt/default&state=${encodeURIComponent(JSON.stringify(state))}`;
  };
  const loginWithGoogle = () => {
    const uri = buildOAuthUrl('Google');
    window.location.replace(uri);
  };
  const loginWithGitHub = () => {
    const uri = buildOAuthUrl('GithubShim');
    window.location.replace(uri);
  };
  const loginWithLinkedIn = () => {
    const uri = buildOAuthUrl('LinkedIn');
    window.location.replace(uri);
  };
  const loginWithAzure = () => {
    const uri = buildOAuthUrl('Azure');
    window.location.replace(uri);
  };
  const confirmOAuthCode = useMemo(
    () =>
      async (
        code: string,
        state?: string,
      ): Promise<{ isNewUser: boolean; goto?: string }> => {
        const redirectUri = getOAuthRedirectUri();
        const json = await exchangeOAuthToken(code, redirectUri);
        storeAccessToken(json.access_token);
        storeRefreshToken(json.refresh_token);
        let inviteId: string | undefined;
        let goto: string | undefined;
        try {
          const jState = state ? JSON.parse(state) : {};
          inviteId = jState.inviteId;
          goto = jState.goto;
        } catch (e) {
          console.error(e);
        }

        let isNewUser = false;
        const newUser = await (
          inviteId
            ? acceptInvite(inviteId).then(() => {
                return getUser();
              })
            : getUser().catch(async (e) => {
                if (e?.response?.status === 404) {
                  isNewUser = true;
                  await putUser();
                  const newUser = await getUser();
                  if (newUser) return newUser;
                }
                throw e;
              })
        ).catch((e) => {
          storeAccessToken(undefined);
          storeRefreshToken(undefined);
          throw e;
        });

        setUser(newUser);
        setIsAuthenticated(true);
        track('web_app_login_successfully');
        return { isNewUser, goto };
      },
    [],
  );

  const refreshUser = useCallback(() => {
    return getUser().then((updatedUser) => setUser(updatedUser));
  }, []);

  const logout = () => {
    storeAccessToken(undefined);
    storeRefreshToken(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      mixpanel.reset();
    }
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isProd,
        license,
        planExpirationInDays,
        refreshUser,
        setIsProd,
        loginWithGoogle,
        loginWithGitHub,
        loginWithLinkedIn,
        loginWithAzure,
        logout,
        confirmOAuthCode,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
export default AuthContextProvider;
