import { capitalize } from '@mui/material';

export interface Vulnerability {
  id: string;
  name: string;
  category: string;
  endpoint: string;
  curl_cmd: string;
  evidences: number[];
  description: string;
  remediation: string;
  owasp_name?: string;
  owasp_link?: string;
  application_id?: string;
  false_positive?: boolean;
  status?: string;
  parameter?: boolean;
  tickets?: VulnerabilityTicket[];
}

export const statusToTitle = (status?: string) => {
  switch (status) {
    case 'false_positive_claimed':
      return 'False Positive Claimed';

    default:
      return capitalize(status?.replaceAll('_', ' ') ?? '');
  }
};

export interface VulnerabilityTicket {
  id: string;
  integration_id: string;
  integration_type: string;
  external_id: string;
  name: string;
  status?: string;
  link?: string;
  jira?: {
    project_id: string;
    key?: string;
  };
}
