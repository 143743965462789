import { get, post, put } from './BaseService';

export async function getUser() {
  const res = await get('/user?user_id=me');

  return res.data;
}

export async function putUser() {
  const res = await put('/user', {});

  return res.data;
}

export async function exchangeOAuthToken(code: string, redirectUri: string) {
  const res = await get('/auth/login', {
    params: { code, redirect_uri: redirectUri },
  });

  return res.data;
}

export async function acceptInvite(inviteId: string) {
  const res = await post('/acceptInvite?invite=' + inviteId, {});

  return res.data;
}

export async function getAuthUser(token: string) {
  const res = await get('/oauthUser', {
    headers: { Authorization: 'Bearer ' + token },
  });

  return res.data;
}
