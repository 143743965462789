import { Box, SxProps } from '@mui/material';

import UsersGrid from '../components/Users/UsersGrid';

const GRID_CONTAINER: SxProps = {
  width: '100%',
  px: 2,
  my: 2,
  bgcolor: 'white',
  borderRadius: 1,
  border: 'solid 1px #D3D3D3',
  boxShadow: '0px 0px 4px 0px #0000001A',
};

const Users = () => {
  return (
    <Box sx={GRID_CONTAINER}>
      <UsersGrid />
    </Box>
  );
};

export default Users;
