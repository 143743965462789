import { get, httpDelete, patch, put } from './BaseService';

export async function getOrgUsers() {
  const res = await get('/user');

  return res.data;
}

export async function getInvites() {
  const res = await get('/organizationinvites');

  return res.data?.invites?.filter((i: any) => i.status === 'pending');
}

export async function createInvite(email: string, role?: 'Admin' | 'User') {
  const res = await put('/invite', { email, role });

  return res.data;
}

export async function deleteInvite(inviteId: string) {
  const res = await httpDelete('/invite?invite_id=' + inviteId, {});

  return res.data;
}

export async function updateUserRole(userId: string, role: 'Admin' | 'User') {
  const res = await patch(`/user?user_id=${userId}&role=${role}`);

  return res.data;
}

export async function deleteUser(userId: string) {
  const res = await httpDelete('/user?user=' + userId, {});

  return res.data;
}
