import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SecuringUnderline } from '../../assets/svgs/SecuringUnderline';
import { GithubIcon } from '../../assets/svgs/icons/GithubIcon';
import { GoogleIcon } from '../../assets/svgs/icons/GoogleIcon';
import { LinkedInIcon } from '../../assets/svgs/icons/LinkedInIcon';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  usePersistedJsonState,
  usePersistedState,
} from '../../hooks/usePersistedState';
import { exchangeOAuthToken, getAuthUser } from '../../services/AuthService';
import { analyticsIdentify, track } from '../../utils/analytics';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const SUBTITLE: SxProps = { color: 'white', fontSize: 18, textAlign: 'start' };

const LOGIN_LOADING_BUTTON: SxProps = {
  bgcolor: '#F5F8FD',
  p: 2,
  height: 68,
};

const GOOGLE_BUTTON: SxProps = {
  'bgcolor': '#F5F8FD',
  'p': 2,
  'height': 68,
  ':hover': {
    bgcolor: '#E8F0F8',
  },
};

const LINKEDIN_BUTTON: SxProps = {
  'bgcolor': '#007EBB',
  'p': 2,
  'height': 68,
  ':hover': {
    bgcolor: '#00A0DC',
  },
};

const GITHUB_BUTTON: SxProps = {
  'bgcolor': '#FF8C00',
  'p': 2,
  'height': 68,
  ':hover': {
    bgcolor: '#FFA500',
  },
};

const GOOGLE_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'black',
};

const GITHUB_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'white',
};

const LINKEDIN_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'white',
};

const TERMS_WRAPPER: SxProps = {
  textAlign: 'start',
  color: '#CCD2E9',
};

const LINK: CSSProperties = {
  color: '#FF8C00',
};

// TODO: all the selenium & burp wizards can be merged into a single component
const SeleniumOnboardLogin = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { show } = useContext(SnackbarContext);

  const [loginProvider, setLoginProvider] = usePersistedState(
    'seleniumLoginProvider',
    undefined,
    sessionStorage,
  );
  const [token, setToken] = usePersistedJsonState<{
    accessToken: string;
    userId: string;
    email: string;
  }>('seleniumAuth', undefined, sessionStorage);

  const [isLoading, setIsLoading] = useState(() => {
    // start loading instantly if a code exists
    const code = searchParams.get('code') ?? '';
    return !!code;
  });

  const redirectUrl = window.location.origin + window.location.pathname;

  const authenticate = useCallback(
    async (provider: 'Google' | 'GithubShim' | 'LinkedIn' | 'Azure') => {
      const queryString = `identity_provider=${provider}&scope=pynt/default&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`;
      const url = `${process.env.REACT_APP_OAUTH_BASE_URL}/oauth2/authorize?${queryString}`;
      window.location.href = url;
    },
    [],
  );

  useEffect(() => {
    const code = searchParams.get('code') ?? '';
    // const state = searchParams.get('state') ?? '';
    if (!code) return;

    setSearchParams((p) => {
      p.delete('code');
      p.delete('state');
      return p;
    });

    setIsLoading(true);
    exchangeOAuthToken(code, redirectUrl)
      .then(async (token) => {
        const user = await getAuthUser(token.access_token);
        track('onboarding-1-signup-success', { wizard: 'selenium' });

        setToken({
          accessToken: token.access_token,
          userId: user.user_id,
          email: user.email,
        });
      })
      .catch((e) => {
        track('onboarding-1-signup-error', {
          wizard: 'selenium',
          error: `${e}`,
        });
        show(e.toString(), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    if (token) {
      analyticsIdentify(token.userId, token.email);

      navigate('../setup');
    }
  }, [token]);

  return (
    <Stack alignItems={'center'} p={2} height={'100%'} maxHeight={'650px'}>
      <Stack
        direction={'column'}
        spacing={1}
        pt={5}
        maxWidth={'640px'}
        height={'100%'}
      >
        <Stack spacing={1} direction={'row'} position={'relative'}>
          <Typography sx={TITLE}>Start</Typography>
          <Box>
            <Typography sx={TITLE} zIndex={1000} position={'relative'}>
              securing
            </Typography>
            <Box position="absolute" bottom={-4}>
              <SecuringUnderline />
            </Box>
          </Box>
          <Typography sx={TITLE}>your APIs in Selenium</Typography>
        </Stack>
        <Typography sx={SUBTITLE}>
          Sign up to Pynt to integrate it with Selenium and secure your APIs in
          a few minutes!
        </Typography>
        <Box height={48} />
        <Stack direction={'column'} spacing={2} maxWidth={'95%'}>
          <LoadingButton
            sx={
              isLoading && loginProvider === 'Google'
                ? LOGIN_LOADING_BUTTON
                : GOOGLE_BUTTON
            }
            disabled={isLoading}
            loading={isLoading && loginProvider === 'Google'}
            onClick={() => {
              track('onboarding-1-signup-with-google');
              track('selenium_onboard_login_with_google_button_click');

              setLoginProvider('Google');
              authenticate('Google');
            }}
          >
            {isLoading && loginProvider === 'Google' ? (
              <></>
            ) : (
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
              >
                <GoogleIcon size={32} />
                <Typography sx={GOOGLE_BUTTON_LABEL}>
                  Sign in with Google
                </Typography>
              </Stack>
            )}
          </LoadingButton>
          <LoadingButton
            sx={
              isLoading && loginProvider === 'GitHub'
                ? LOGIN_LOADING_BUTTON
                : GITHUB_BUTTON
            }
            disabled={isLoading}
            loading={isLoading && loginProvider === 'GitHub'}
            onClick={() => {
              track('onboarding-1-signup-with-github');
              track('selenium_onboard_login_with_github_button_click');

              setLoginProvider('GitHub');
              authenticate('GithubShim');
            }}
          >
            {isLoading && loginProvider === 'GitHub' ? (
              <></>
            ) : (
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
              >
                <GithubIcon />
                <Typography sx={GITHUB_BUTTON_LABEL}>
                  Sign in with GitHub
                </Typography>
              </Stack>
            )}
          </LoadingButton>
          <LoadingButton
            sx={
              isLoading && loginProvider === 'LinkedIn'
                ? LOGIN_LOADING_BUTTON
                : LINKEDIN_BUTTON
            }
            disabled={isLoading}
            loading={isLoading && loginProvider === 'LinkedIn'}
            onClick={() => {
              track('onboarding-1-signup-with-LinkedIn');
              track('selenium_onboard_login_with_LinkedIn_button_click');

              setLoginProvider('LinkedIn');
              authenticate('LinkedIn');
            }}
          >
            {isLoading && loginProvider === 'LinkedIn' ? (
              <></>
            ) : (
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
              >
                <LinkedInIcon />
                <Typography sx={LINKEDIN_BUTTON_LABEL}>
                  Sign in with LinkedIn
                </Typography>
              </Stack>
            )}
          </LoadingButton>
          <Typography color="white">
            Or Sign up with
            <LoadingButton
              variant="text"
              disabled={isLoading && loginProvider === 'Azure'}
              onClick={() => {
                track('onboarding-1-signup-with-azure');
                track('selenium_onboard_login_with_azure_button_click');

                setLoginProvider('Azure');
                authenticate('Azure');
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}
              >
                {isLoading && loginProvider === 'Azure' ? (
                  <CircularProgress size={16} />
                ) : (
                  <></>
                )}
                <Typography sx={[LINK, { textDecoration: 'underline' }]}>
                  Azure AD
                </Typography>
              </Stack>
            </LoadingButton>
            (for business users only).
          </Typography>
        </Stack>
        <Box flex={1} />
        <Box sx={TERMS_WRAPPER}>
          We will not make any use of the auth provider without your permission.
          <br />
          <br />
          By logging in or signing up, you agree to abide by our policies,
          including our{' '}
          <a
            href="https://github.com/pynt-io/pynt/blob/main/EULA.md"
            style={LINK}
            target="_blank"
            onClick={() => {
              track('onboarding-1-terms-of-service');
            }}
            rel="noreferrer"
          >
            <u>Terms of Service</u>
          </a>{' '}
          and{' '}
          <a
            href="https://github.com/pynt-io/pynt/blob/main/Privacy-Policy.md"
            style={LINK}
            target="_blank"
            onClick={() => {
              track('onboarding-1-privacy-policy');
            }}
            rel="noreferrer"
          >
            <u>Privacy Policy</u>
          </a>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SeleniumOnboardLogin;
