import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ScanHistoryItem } from '../models/scanHistory';
import { TestCase } from '../models/test-case';
import { getApplicationById } from '../services/ApplicationsService';
import { getAppScans } from '../services/ScansService';
import { getTestCases } from '../services/TestsService';
import { Application } from './User';

export interface ApplicationContextProps {
  application?: Application;
  isLoadingApplication: boolean;
  error?: string;
  refetchApplication: () => Promise<Application>;

  latestScans?: ScanHistoryItem[];
  isLoadingLatestScans: boolean;

  testCases?: TestCase[];
  isLoadingTestCases: boolean;
  fetchTestCases: () => Promise<TestCase>;

  endpointsRisks: Record<string, number>;
  setApplicationId: (id: string | undefined) => any;
}

export const ApplicationContext = createContext<ApplicationContextProps | null>(
  null,
);

function ApplicationContextProvider(props: any) {
  const [application, setApplication] = useState<Application>();
  const [error, setError] = useState<string>();
  const [applicationId, setApplicationId] = useState<string>();
  const [isLoadingApplication, setIsLoadingApplication] = useState(false);
  const [latestScans, setLatestScans] = useState<ScanHistoryItem[]>();
  const [isLoadingLatestScans, setIsLoadingLatestScans] = useState(false);
  const [testCases, setTestCases] = useState<TestCase[]>();
  const [isLoadingTestCases, setIsLoadingTestCases] = useState(false);

  const endpointsRisks = useMemo(() => {
    const risks = (application as any)?.endpoints_risks;
    if (!risks) return {};

    return Object.fromEntries(
      Object.entries(risks).map(([k, v]: any) => [k?.toUpperCase(), v]),
    ) as any;
  }, [application]);

  useEffect(() => {
    if (!applicationId) {
      setIsLoadingApplication(false);
      setApplication(undefined);
      setTestCases(undefined);
      return;
    }

    setIsLoadingApplication(true);
    setApplication(undefined);
    setError(undefined);

    getApplicationById(applicationId)
      .then((app) => {
        setApplication(app);
      })
      .catch((e) => {
        setError(e.message || e.toString());
      })
      .finally(() => {
        setIsLoadingApplication(false);
      });
  }, [applicationId]);

  const fetchLatestScans = useCallback(async (applicationId: string) => {
    const scans = await getAppScans(applicationId);

    return scans.filter((s: any) => s.application === applicationId);
  }, []);

  const fetchTestCases = useCallback(async () => {
    const scanId = application?.data_sources.scan?.source_id;

    if (!scanId) {
      setTestCases([]);
      return;
    }

    setIsLoadingTestCases(true);
    const tests = await getTestCases(scanId).finally(() => {
      setIsLoadingTestCases(false);
    });

    setTestCases(tests);

    return tests;
  }, [application]);

  const refetchApplication = useCallback(async () => {
    if (!applicationId || isLoadingApplication) return;

    return getApplicationById(applicationId).then((app) => {
      setApplication(app);
      return app;
    });
  }, [applicationId, isLoadingApplication]);

  useEffect(() => {
    if (!applicationId) {
      setIsLoadingLatestScans(false);
      setLatestScans(undefined);
      return;
    }

    setIsLoadingLatestScans(true);
    fetchLatestScans(applicationId)
      .then((scans) => {
        setLatestScans(scans);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoadingLatestScans(false);
      });
  }, [applicationId, fetchLatestScans]);

  useEffect(() => {
    if (!application) return;
    fetchTestCases();
  }, [application]);

  return (
    <ApplicationContext.Provider
      value={{
        application,
        isLoadingApplication,
        error,
        refetchApplication,

        latestScans,
        isLoadingLatestScans,

        testCases,
        isLoadingTestCases,
        fetchTestCases,

        setApplicationId,
        endpointsRisks,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationContextProvider;
