import { GridColDef, GridColumnsInitialState } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useContext, useEffect, useMemo } from 'react';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import PyntDataGrid from '../Common/PyntDataGrid';
import TestCategoryChip from './TestCategoryChip';
import TestStatusChip from './TestStatusChip';

interface Props {
  hideFooter?: boolean;
  autoHeight?: boolean;
  autoPageSize?: boolean;
}

export default function TestCasesGrid({
  hideFooter,
  autoHeight,
  autoPageSize,
}: Props) {
  const { isLoadingTestCases, testCases, application, fetchTestCases } =
    useContext(ApplicationContext) as ApplicationContextProps;

  useEffect(() => {
    if (!application) return;
    fetchTestCases();
  }, [application]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          valueGetter: ({ row }) => row.status.toLowerCase(),
          renderCell: ({ value }) => <TestStatusChip status={value} />,
          type: 'singleSelect',
          valueOptions: ['failed', 'passed'],
        },
        {
          field: 'test_type',
          headerName: 'Test Name',
          flex: 1.5,
        },
        {
          field: 'owasp_name',
          headerName: 'Category',
          flex: 1,
          valueGetter: ({ row }) => row.owasp_name || row.category,
          renderCell: ({ value }) => <TestCategoryChip category={value} />,
        },
        // {
        //   field: 'continue',
        //   headerName: '',
        //   renderCell: () => <ChevronRightIcon />,
        // },
      ] as GridColDef[],
    [],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(
      'TestCasesGridColumnsState',
      {
        columnVisibilityModel: {},
      },
    );

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      sorting: { sortModel: [{ field: 'status', sort: 'asc' }] },
      columns: gridColumnsState,
    };
  }, [gridColumnsState]);

  useEffect(() => {}, []);

  return (
    <PyntDataGrid
      autoHeight={autoHeight}
      autoPageSize={autoPageSize}
      hideFooter={hideFooter}
      sx={{ borderRadius: 1 }}
      rows={testCases ?? []}
      getRowId={(row) => `${row.test_type}-${row.category}-${row.sub_category}`}
      loading={!testCases && isLoadingTestCases}
      columns={columns}
      initialState={gridInitialState}
      onStateChange={(state) => {
        setGridColumnsState(state.columns);
      }}
    />
  );
}
