import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import azureDevOps from '../../assets/images/integrations/azureDevops.png';
import github from '../../assets/images/integrations/github.png';
import gitlab from '../../assets/images/integrations/gitlab.png';
import jenkins from '../../assets/images/integrations/jenkins.png';
import newman from '../../assets/images/integrations/newman.png';
import python from '../../assets/images/integrations/python.png';
import restAssured from '../../assets/images/integrations/restAssured.png';
import postman from '../../assets/images/postman.png';
import { DocumentationIcon } from '../../assets/svgs/icons/DocumentationIcon';
import { ScanIcon2 } from '../../assets/svgs/icons/ScanIcon2';
import { BRAND_ORANGE } from '../../constants/colors';
import {
  Application,
  UserContext,
  UserContextProps,
} from '../../contexts/User';
import { track } from '../../utils/analytics';
import CustomDrawer from '../Common/CustomDrawer';
import TextFieldTitle from '../Common/TextFieldTitle';
import EditApplicationDrawer from '../Setup/EditApplicationDrawer';
import IntegrationOptionChip from '../Setup/EditSource/IntegrationOptionChip';
import PostmanRunScanWizard from './PostmanRunScanWizard';
import RunScanInstructionsWizard from './RunScanInstructionsWizard';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const INTEGRATION_OPTIONS: {
  // eslint-disable-next-line no-unused-vars
  [key in RunScanType]: { options: Integration[] };
} = {
  scan: {
    options: [
      {
        type: 'postman',
        title: 'Postman',
        icon: <img src={postman} height={20} width={20} />,
      },
      {
        type: 'newman',
        title: 'Newman',
        icon: <img src={newman} height={20} width={20} />,
      },
      {
        type: 'python',
        title: 'Python',
        icon: <img src={python} height={20} width={20} />,
      },
      {
        type: 'restAssured',
        title: 'Rest Assured',
        icon: <img src={restAssured} height={20} width={20} />,
      },
    ],
  },
  cloud_scan: {
    options: [
      {
        type: 'postman',
        title: 'Postman',
        icon: <img src={postman} height={20} width={20} />,
      },
    ],
  },
  cicd: {
    options: [
      {
        type: 'github',
        icon: <img src={github} height={20} width={20} />,
        title: 'GitHub Actions',
      },
      {
        type: 'gitlab',
        icon: <img src={gitlab} height={20} width={20} />,
        title: 'GitLab',
      },
      {
        type: 'jenkins',
        icon: <img src={jenkins} height={20} width={20} />,
        title: 'Jenkins',
      },
      {
        type: 'azureDevOps',
        icon: <img src={azureDevOps} height={20} width={20} />,
        title: 'Azure DevOps',
      },
    ],
  },
};

type IntegrationType =
  | 'postman'
  | 'newman'
  | 'python'
  | 'restAssured'
  | 'github'
  | 'gitlab'
  | 'jenkins'
  | 'azureDevOps'
  | 'custom';

interface Integration {
  type: IntegrationType;
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  comingSoon?: boolean;
  link?: string;
}

type RunScanType = 'scan' | 'cloud_scan' | 'cicd';

interface RunScanDataBase {
  appId?: string;
  type?: string;
  integration?: string;
}

export interface PostmanRunScanData extends RunScanDataBase {
  type?: 'scan';
  integration?: 'postman';
  key?: string;
  destinationWorkspaceId?: string;
  testCollectionId?: string;
  environmentId?: string;
}

export interface CloudPostmanRunScanData extends RunScanDataBase {
  type?: 'cloud_scan';
  integration?: 'postman';
  key?: string;
  destinationWorkspaceId?: string;
  testCollectionId?: string;
  environmentId?: string;
}

export interface NormalRunScanData extends RunScanDataBase {
  type?: 'scan';
  integration?: 'newman' | 'python' | 'restAssured';
}

interface CustomScanRunScanData extends RunScanDataBase {
  type?: 'scan';
  integration?: 'custom';
}

interface CustomCiCdRunScanData extends RunScanDataBase {
  type?: 'cicd';
  integration?: 'scan';
}

export type RunScanData =
  | PostmanRunScanData
  | CloudPostmanRunScanData
  | CustomScanRunScanData
  | NormalRunScanData
  | CustomCiCdRunScanData;

interface Props {
  application?: Application;
  hideAppSelector?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const RunScanDrawer = ({
  application,
  hideAppSelector,
  isOpen,
  setIsOpen,
}: Props) => {
  const { applications } = useContext(UserContext) as UserContextProps;
  const [data, setData] = useState<RunScanData>({ appId: application?.app_id });
  const [showCreateAppDrawer, setShowCreateAppDrawer] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const integrations = useMemo(
    () => INTEGRATION_OPTIONS[data.type || 'scan']?.options,
    [data.type],
  );

  // Clean data upon drawer close
  useEffect(() => {
    if (isOpen) return;

    setData({});
    setDisabled(false);
  }, [isOpen]);

  useEffect(() => {
    setData((data) => ({ ...data, appId: application?.app_id }));
  }, [application]);

  useEffect(() => {
    setData((data) => ({
      ...data,
      integration: undefined,
    }));
  }, [integrations]);

  return (
    <>
      <EditApplicationDrawer
        mode="create"
        isOpen={showCreateAppDrawer}
        setIsOpen={() => {
          setShowCreateAppDrawer(false);
        }}
        onSave={(app) => {
          setData((data) => ({ ...data, appId: app.app_id }));
        }}
      />
      <CustomDrawer
        header={{
          title: 'Run Scan',
        }}
        open={isOpen}
        setOpen={() => setIsOpen?.(false)}
      >
        <Stack spacing={2.5}>
          {hideAppSelector ? (
            <></>
          ) : (
            <Box>
              <TextFieldTitle title="Application" />
              <FormControl fullWidth size="small">
                <InputLabel
                  shrink={false}
                  disabled
                  disableAnimation
                  sx={{ lineHeight: 1.3 }}
                >
                  {data.appId ? '' : 'Select...'}
                </InputLabel>
                <Select
                  disableUnderline
                  disabled={disabled}
                  value={data.appId || ''}
                  onChange={(v) => {
                    if (v.target.value === 'add_new') {
                      track(
                        'web_app_run_scan_drawer_add_new_application_button_click',
                      );
                      setShowCreateAppDrawer(true);
                      return;
                    }
                    setData((data) => ({
                      ...data,
                      appId: v.target.value,
                    }));
                  }}
                >
                  <MenuItem
                    value={'add_new'}
                    color={BRAND_ORANGE}
                    sx={{ color: BRAND_ORANGE }}
                  >
                    + add new application
                  </MenuItem>
                  {applications?.map((option) => (
                    <MenuItem key={option.app_id} value={option.app_id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box>
            <TextFieldTitle title="Type" />
            <FormControl fullWidth size="small">
              <Select
                disableUnderline
                disabled={disabled}
                value={data.type || 'scan'}
                onChange={(v) => {
                  track('web_app_run_scan_drawer_select_type_button_click', {
                    integrationType: v.target.value,
                  });
                  return setData((data) => ({
                    ...data,
                    type: v.target.value as any,
                  }));
                }}
              >
                <MenuItem value={'scan'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ScanIcon2 size={20} />
                    <span>Local Scan</span>
                  </Stack>
                </MenuItem>
                <MenuItem value={'cloud_scan'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ScanIcon2 size={20} />
                    <span>Cloud Scan</span>
                  </Stack>
                </MenuItem>
                <MenuItem value={'cicd'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Box sx={SELECT_ICON_WRAPPER}>
                      <DocumentationIcon />
                    </Box>
                    <span>CI/CD</span>
                  </Stack>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {integrations ? (
            <Box>
              <TextFieldTitle title="Integration" />
              <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
                {integrations.map((i) => (
                  <Box key={i.type + '-' + i.title}>
                    <IntegrationOptionChip
                      icon={i.icon}
                      title={i.title}
                      selected={i.type === data.integration}
                      onClick={() => {
                        track(
                          'web_app_run_scan_drawer_select_integration_button_click',
                          {
                            integrationType: data.type,
                            integration: i.title,
                          },
                        );
                        if (i.link) {
                          window.open(i.link, '_blank');
                          return;
                        }
                        setData((data) => ({
                          ...data,
                          integration: i.type as any,
                        }));
                      }}
                      disabled={disabled || i.disabled}
                      comingSoon={i.comingSoon}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : null}
          <Box>
            <Button
              href="https://adc5cfmyo2u.typeform.com/to/gyQ2H0To"
              target="_blank"
              onClick={() => {
                track(
                  'web_app_run_scan_screen_not_sure_how_to_run_pynt_button_click',
                );
              }}
            >
              Not sure how to run Pynt?
            </Button>
          </Box>
          {['postman', 'newman', 'python', 'restAssured'].includes(
            data.integration as any,
          ) ? (
            <PrerequisitesBox />
          ) : (
            <></>
          )}
          {data.integration === 'postman' ? (
            <PostmanRunScanWizard
              data={data}
              setData={setData}
              setDisabled={(disabled) => setDisabled(disabled)}
              onDone={() => setIsOpen(false)}
            />
          ) : (
            <></>
          )}
          {['newman', 'python', 'restAssured'].includes(
            data.integration as any,
          ) ? (
            <RunScanInstructionsWizard data={data} />
          ) : (
            <></>
          )}
          {['github', 'gitlab', 'jenkins', 'azureDevOps'].includes(
            data.integration as any,
          ) ? (
            <>
              <CICDPrerequisitesBox />
              <RunScanInstructionsWizard data={data} />
            </>
          ) : (
            <></>
          )}
        </Stack>
      </CustomDrawer>
    </>
  );
};

export default RunScanDrawer;

const PREREQUISITES_BOX: SxProps = {
  bgcolor: '#F2F2F2',
  padding: '6px 8px 6px 8px',
  color: '#7B7B88',
  borderRadius: '4px',
  fontSize: 14,
};

const PrerequisitesBox = () => {
  return (
    <Typography sx={PREREQUISITES_BOX}>
      View prerequisits{' '}
      <a
        href="https://docs.pynt.io/documentation/security-testing/prerequisites"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#FF8C00' }}
      >
        here
      </a>
      .
    </Typography>
  );
};

const CICDPrerequisitesBox = () => {
  return (
    <Typography sx={PREREQUISITES_BOX}>
      Integrating API Security testing into your CI/CD pipeline using Pynt
      Newman or Pynt command is simple. Explore the required steps and examples
      here:{' '}
      <a
        href="https://docs.pynt.io/documentation/pynt-examples/pynt-on-ci-cd"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#FF8C00' }}
      >
        Pynt on CI/CD
      </a>
      .
    </Typography>
  );
};
