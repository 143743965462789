import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { CSSProperties, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRAND_ORANGE } from '../../constants/colors';
import { track } from '../../utils/analytics';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const TEXT: SxProps = {
  color: 'white',
};

const LINK: CSSProperties = {
  color: BRAND_ORANGE,
};

const SMALL_TEXT: SxProps = {
  color: '#ccd2e9',
};

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const BurpOnboardRun = () => {
  const navigate = useNavigate();

  const handlePrevClick = useCallback(() => {
    track('burp-onboarding-3-previous');
    navigate(-1);
  }, [navigate]);

  const handleNextClick = useCallback(() => {
    track('burp-onboarding-3-finish');
    window.location.href = 'https://www.pynt.io';
  }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Ensure the script has loaded before running the form creation
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'eu1',
          portalId: '26510880',
          formId: 'e498d9dd-6b77-4fed-985a-d8fc6503eaa8',
          target: '#hubspotForm',
        });
      }
    };

    // Clean up to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Stack alignItems={'center'} p={2} height={'100%'}>
      <style>{`
        #hubspotForm {
          color: white;  
        }

        fieldset {
          margin-bottom: 20px !important;
        }
        
        .hs-form h2 {
          font-size: 34px;
          display: none;
        }

        .hs-input {
          border-radius: 10px;
          border: 1px solid #dbdde4;
          background: #fff;
          padding: 16px 22px;
          width: 100% !important;
        }

        .hs-form-field label {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          color: white;
          margin-bottom: 5px;
          display: block;
          text-align: left;
        }
        .hs-error-msgs label {
          color: red;
          font-size: 12px;
        }
        
        .hs-button {
          border-radius: 14px;
          background: #FF8C00;
          cursor: pointer;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: -.187px;
          border: none;
          padding: 10px;
        }
      `}</style>
      <Stack
        direction={'column'}
        spacing={1}
        pt={5}
        maxWidth={'700px'}
        height={'100%'}
      >
        <Typography sx={TITLE} textAlign={'start'}>
          You&apos;re almost done!
        </Typography>

        <Stack direction={'column'} spacing={2} mt={1} alignItems={'start'}>
          <Typography sx={TEXT} fontWeight={600}>
            To fully reach pynt&apos;s potential schedule a demo now!
          </Typography>

          <div id="hubspotForm"></div>

          <Stack direction={'column'} spacing={1} pb={1}>
            <Typography sx={SMALL_TEXT} textAlign={'start'}>
              For any issues, please contact{' '}
              <a href="mailto:support@pynt.io" style={LINK}>
                support@pynt.io
              </a>{' '}
              or join our{' '}
              <a
                href="https://www.pynt.io/community"
                target="_blank"
                rel="noreferrer"
                style={LINK}
                onClick={() => {
                  track('burp-onboarding-3-slack');
                }}
              >
                slack community
              </a>
            </Typography>
          </Stack>
        </Stack>

        <Box height={60} />
        <Stack direction={'row'} justifyContent={'end'} gap={2} pb={5} pt={2}>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handlePrevClick}
            startIcon={<KeyboardArrowLeftRounded />}
          >
            Previous
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handleNextClick}
          >
            Finish
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BurpOnboardRun;
