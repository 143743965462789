import { PyntFilter } from './ApplicationsService';
import { get } from './BaseService';

export async function getEndpoints(filter: PyntFilter = {}) {
  const res = await get('/endpoint', {
    params: { filter: JSON.stringify(filter) },
  });

  const endpoints = res.data;

  // TODO: Remove this when the backend is fixed
  if (filter.limit) {
    return endpoints.slice(0, filter.limit);
  }

  return endpoints;
}

export async function getEndpointsCount(where: PyntFilter['where'] = {}) {
  const res = await get('/endpoint/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}
