import {
  KeyboardArrowDown,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import youtubeIcon from '../../assets/images/youtubeIcon.png';
import { SecuringUnderline } from '../../assets/svgs/SecuringUnderline';
import {
  usePersistedJsonState,
  usePersistedState,
} from '../../hooks/usePersistedState';
import {
  addVariablesToCollection,
  apiKeySessionStorageKey,
  getAllWorkspaces,
  getCollectionsInWorkspace,
} from '../../services/PostmanService';
import { track } from '../../utils/analytics';
import PostmanOnboardExplainModal from './PostmanOnboardExplainModal';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const TEXT: SxProps = {
  color: 'white',
};

const OPTION_BUTTON: SxProps = {
  minHeight: 60,
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '40px',
  whiteSpace: 'nowrap',
};

const LINK: CSSProperties = {
  color: '#FF8237',
  fontFamily: 'Raleway',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '-0.187px',
  textDecorationLine: 'underline',
  flex: 1,
  textAlign: 'end',
};

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const goatCollectionId = '22821296-23bb40ff-e0a6-40eb-8bf1-13016c206f8b';

const PostmanOnboardSetup = () => {
  const navigate = useNavigate();
  const [isExplainerModalOpen, setIsExplainerModalOpen] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [postmanApiKey, setPostmanApiKey] = usePersistedState(
    apiKeySessionStorageKey,
    undefined,
    sessionStorage,
  );
  const [workspaces, setWorkspaces] = usePersistedJsonState<any[]>(
    'postman_onboard_workspaces',
    undefined,
    sessionStorage,
  );
  const [selectedWorkspace, setSelectedWorkspace] = usePersistedState(
    'postman_onboard_selected_workspace',
    undefined,
    sessionStorage,
  );
  const [collections, setCollections] = usePersistedJsonState<
    any[] | undefined
  >('postman_onboard_collections', undefined, sessionStorage);
  const [selectedCollection, setSelectedCollection] = usePersistedState(
    'postman_onboard_selected_collection',
    undefined,
    sessionStorage,
  );

  useEffect(() => {
    if (!selectedWorkspace) {
      setCollections([]);
      return;
    }

    if (
      selectedCollection &&
      collections?.length &&
      collections.some((c) => c.workspaceId === selectedWorkspace)
    ) {
      return;
    }

    setCollections(undefined);
    getCollectionsInWorkspace(selectedWorkspace)
      .then((collections: any[]) => {
        collections.splice(0, 0, {
          id: goatCollectionId,
          uid: goatCollectionId,
          name: 'goat (example collection)',
        });
        setCollections(collections);
        setSelectedCollection(collections?.[0].id);
      })
      .catch(console.error);
  }, [selectedWorkspace]);

  const handleSubmitClick = useCallback(() => {
    track('onboarding-2-submit');

    setIsLoadingSubmit(true);

    getAllWorkspaces()
      .then((workspaces) => {
        setWorkspaces(workspaces);
        setSelectedWorkspace(workspaces?.[0]?.id);
      })
      .catch((e) => {
        alert('Invalid Postman api key');
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  }, []);

  const handleNextClick = useCallback(() => {
    const workspace = workspaces?.find((w) => w.id === selectedWorkspace);
    const collection = collections?.find((c) => c.id === selectedCollection);
    if (
      !selectedCollection ||
      !selectedWorkspace ||
      !workspace ||
      !collection
    ) {
      alert('Select a workspace and collection');
      return;
    }

    track('onboarding-2-next');

    setIsLoadingNext(true);

    addVariablesToCollection(collection.uid, selectedWorkspace)
      .then(() => {
        navigate('../run');
      })
      .finally(() => {
        setIsLoadingNext(false);
      });
  }, [selectedCollection, selectedWorkspace, workspaces, collections]);

  return (
    <>
      <PostmanOnboardExplainModal
        open={isExplainerModalOpen}
        onClose={() => {
          setIsExplainerModalOpen(false);
        }}
      />
      <Stack alignItems={'center'} p={2} height={'100%'}>
        <Stack
          direction={'column'}
          spacing={1}
          pt={5}
          maxWidth={'700px'}
          height={'100%'}
        >
          <Box sx={{ display: 'inline', textAlign: 'start' }}>
            <Box sx={{ display: 'inline-block', position: 'relative' }}>
              <Typography
                sx={[TITLE, { whiteSpace: 'nowrap' }]}
                zIndex={1000}
                position={'relative'}
              >
                Fork Pynt
              </Typography>
              <Box position="absolute" bottom={-4}>
                <SecuringUnderline />
              </Box>
            </Box>
            <Typography sx={[TITLE]} display={'inline'}>
              {' '}
              to your workspace to start testing your APIs for security
            </Typography>
          </Box>
          <Box height={24} />
          <Stack direction={'row'} flexWrap={'wrap'} rowGap={2} gap={2}>
            <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('onboarding-2-about');
                  setIsExplainerModalOpen(true);
                }}
              >
                About Postman & Pynt integration
              </Button>
            </OptionButtonBlurWrapper>
            <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('onboarding-2-prerequisites');
                  window.open(
                    'https://docs.pynt.io/documentation/onboarding/prerequisites',
                    '_blank',
                    'noreferrer',
                  );
                }}
              >
                Prerequisites
              </Button>
            </OptionButtonBlurWrapper>
            <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                endIcon={
                  <img
                    src={youtubeIcon}
                    width={34}
                    style={{ marginInlineStart: 8 }}
                  />
                }
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('onboarding-2-tutorial');
                  window.open(
                    'https://www.youtube.com/watch?v=lkQam7zeZD0',
                    '_blank',
                    'noreferrer',
                  );
                }}
              >
                Tutorial video
              </Button>
            </OptionButtonBlurWrapper>
          </Stack>
          <Box height={24} />
          <Stack direction={'row'} alignItems={'start'} spacing={2}>
            <Stack direction={'column'} spacing={5} flex={1}>
              <Stack alignItems={'end'} flex={1} spacing={1}>
                <Stack
                  direction={'row'}
                  alignSelf={'stretch'}
                  alignItems={'center'}
                  spacing={8}
                >
                  <Typography sx={TEXT}>Your Postman API Key</Typography>
                  <TextField
                    variant="outlined"
                    inputProps={{ 'aria-label': 'API key' }}
                    disabled={isLoadingNext}
                    value={postmanApiKey}
                    type="password"
                    onChange={(e) => setPostmanApiKey(e.target.value)}
                    sx={{
                      'input': { color: 'white' },
                      'flex': 1,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#40475C',
                        },
                      },
                    }}
                  />
                </Stack>

                <a
                  style={LINK}
                  target="_blank"
                  href="https://learning.postman.com/docs/developer/postman-api/authentication/#generate-a-postman-api-key"
                  rel="noreferrer"
                  onClick={() => {
                    track('onboarding-2-cant');
                  }}
                >
                  I can&apos;t find my Postman&apos;s API key. How do I generate
                  it?
                </a>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <Typography sx={TEXT}>Your destination workspace</Typography>
                <Select
                  variant="outlined"
                  disabled={isLoadingNext || !workspaces}
                  inputProps={{ 'aria-label': 'goat (example collection)' }}
                  IconComponent={() => (
                    <Stack
                      justifyContent={'center'}
                      px={1}
                      sx={{
                        borderInlineStart: '1px #40475C solid',
                        position: 'absolute',
                        right: 0,
                        top: 1,
                        bottom: 1,
                        height: 'calc(100% - 2px)',
                        pointerEvents: 'none',
                        textAlign: 'start',
                      }}
                    >
                      <KeyboardArrowDown />
                    </Stack>
                  )}
                  sx={{
                    'textAlign': 'start',
                    'flex': 1,
                    'color': 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: 'white !important',
                    },
                  }}
                  value={selectedWorkspace || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== selectedWorkspace) {
                      track('onboarding-2-workspace', { value });
                      setSelectedWorkspace(value);
                    }
                  }}
                >
                  {workspaces?.length ? (
                    <></>
                  ) : (
                    <MenuItem value={''} disabled>
                      {isLoadingSubmit ? 'Loading...' : 'None'}
                    </MenuItem>
                  )}
                  {workspaces?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={11}>
                <Typography sx={TEXT}>Your test collection</Typography>
                <Select
                  variant="outlined"
                  disabled={isLoadingNext || !workspaces}
                  inputProps={{ 'aria-label': 'goat (example collection)' }}
                  IconComponent={() => (
                    <Stack
                      justifyContent={'center'}
                      px={1}
                      sx={{
                        borderInlineStart: '1px #40475C solid',
                        position: 'absolute',
                        right: 0,
                        top: 1,
                        bottom: 1,
                        height: 'calc(100% - 2px)',
                        pointerEvents: 'none',
                      }}
                    >
                      <KeyboardArrowDown />
                    </Stack>
                  )}
                  sx={{
                    'textAlign': 'start',
                    'flex': 1,
                    'color': 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: 'white !important',
                    },
                  }}
                  value={selectedCollection || ''}
                  onChange={(e) => {
                    track('onboarding-2-collection', { value: e.target.value });
                    setSelectedCollection(e.target.value);
                  }}
                >
                  {collections?.length ? (
                    <></>
                  ) : (
                    <MenuItem value={''} disabled>
                      {!collections ? 'Loading...' : 'None'}
                    </MenuItem>
                  )}
                  {collections?.map((col) => (
                    <MenuItem key={col.id} value={col.id}>
                      {col.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Stack>
            <LoadingButton
              variant="outlined"
              sx={{ height: 56, minWidth: '100px' }}
              onClick={handleSubmitClick}
              loading={isLoadingSubmit}
              disabled={isLoadingNext}
            >
              Submit
            </LoadingButton>
          </Stack>
          <Box height={48} />
          <Stack direction={'row'} justifyContent={'end'}>
            <LoadingButton
              variant="contained"
              loading={isLoadingNext}
              sx={SUBMIT_BUTTON}
              endIcon={<KeyboardArrowRightRounded fontSize="large" />}
              onClick={handleNextClick}
            >
              Next
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const OptionButtonBlurWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={(theme) => ({
          borderWidth: 1.5,
          filter: 'blur(7px)',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
          borderRadius: '14px',
          height: '100%',
          width: '100%',
          position: 'absolute',
        })}
      ></Box>
      {children}
    </Box>
  );
};

export default PostmanOnboardSetup;
