import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  usePersistedJsonState,
  usePersistedState,
} from '../hooks/usePersistedState';
import { getApplications } from '../services/ApplicationsService';
import { getAvailableFeatures } from '../services/FeaturesService';
import { getSources } from '../services/SourcesService';
import { AuthContext } from './Auth';

export interface APISource {
  source_id: string;
  name: string;
  type:
    | 'swagger'
    | 'swagger_file'
    | 'postman_file'
    | 'aws_api_gw'
    | 'azure_api_gw'
    | 'kong_api_gw'
    | 'scan';
  status: string;
  application: string;
  properties: { name: string; value: any }[];
}

export interface Application {
  app_id: string;
  name: string;
  number_of_endpoints?: number;
  risk?: number;
  last_scan?: string;
  last_scan_integration?: string;
  vulnerabilities?: number;
  tests?: number;
  data_sources: {
    data_sources: any;
    scan?: {
      source_id: string;
      name: string;
    };
    swagger?: {
      source_id: string;
      name: string;
    };
    aws_api_gw?: {
      source_id: string;
      name: string;
    };
    azure_api_gw?: {
      source_id: string;
      name: string;
    };
  };
}

export interface UserContextProps {
  apiSources?: APISource[];
  fetchApiSources: () => Promise<APISource[]>;
  isLoadingApiSources: boolean;
  applications?: Application[];
  selectedApplication?: Application;
  fetchApplications: () => Promise<Application[]>;
  isLoadingApplications: boolean;
  getAppByIdSync: (id: string) => Application | undefined;
  selectedApplicationId: string | undefined;
  setSelectedApplicationId: (id: string) => any;
  features?: Record<string, { feature_enabled: boolean }>;
}

export const UserContext = createContext<UserContextProps | null>(null);

function UserContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);

  const [features, setFeatures] = usePersistedJsonState<
    Record<string, { feature_enabled: boolean }> | undefined
  >('features');

  const [apiSources, setApiSources] = useState<APISource[]>();
  const [isLoadingApiSources, setIsLoadingApiSources] = useState(false);

  const [applications, setApplications] = useState<Application[]>();
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);

  const [selectedApplicationId, setSelectedApplicationId] = usePersistedState(
    'selectedApplicationId',
    '*',
  );

  const selectedApplication = useMemo(() => {
    if (!selectedApplicationId || selectedApplicationId === '*') {
      return undefined;
    }

    const app = applications?.find(
      (application) => application.app_id === selectedApplicationId,
    );

    return app;
  }, [applications, selectedApplicationId]);

  const fetchApiSources = useMemo(
    () => async () => {
      if (isLoadingApiSources) return;

      setIsLoadingApiSources(true);

      const apiSources = await getSources()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingApiSources(false);
        });
      setApiSources(apiSources);

      return apiSources;
    },
    [isLoadingApiSources],
  );

  const fetchApplications = useMemo(
    () => async () => {
      if (isLoadingApplications) return;

      setIsLoadingApplications(true);

      const applications = await getApplications()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingApplications(false);
        });

      setApplications(applications);

      return applications;
    },
    [isLoadingApplications],
  );

  const fetchAvailableFeatures = useCallback(async () => {
    const features = await getAvailableFeatures().catch((e) => {
      console.error(e);
    });
    setFeatures(features);

    return features;
  }, []);

  // reset on logout
  useEffect(() => {
    if (isAuthenticated) return;

    setApiSources(undefined);
    setApplications(undefined);
  }, [isAuthenticated]);

  // fetch code data when app loads
  useEffect(() => {
    if (!isAuthenticated) return;

    fetchApiSources();
    fetchApplications();
  }, [isAuthenticated]);

  // fetch available features
  useEffect(() => {
    if (!isAuthenticated) return;

    fetchAvailableFeatures();
  }, [isAuthenticated]);

  const getAppByIdSync = useMemo(
    () => (id: string) => {
      return applications?.find((a) => a.app_id === id);
    },
    [applications],
  );

  return (
    <UserContext.Provider
      value={{
        apiSources,
        isLoadingApiSources,
        fetchApiSources,
        applications,
        isLoadingApplications,
        fetchApplications,
        getAppByIdSync,
        selectedApplicationId,
        setSelectedApplicationId,
        selectedApplication,
        features,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
