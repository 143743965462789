import { LoadingButton } from '@mui/lab';
import { Box, Stack, SxProps, TextField } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import CopyField from '../components/Common/CopyTextField';
import TextFieldTitle from '../components/Common/TextFieldTitle';
import { AuthContext } from '../contexts/Auth';
import {
  getStoredAccessToken,
  getStoredRefreshToken,
} from '../services/BaseService';
import { updateOrgName } from '../services/OrgService';

const GRID_CONTAINER: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  pt: 4,
};

const TEXT_FIELD: SxProps = {
  '.MuiFilledInput-root': {
    backgroundColor: 'white',
    border: 'solid 1px #D3D3D3',
    minWidth: 320,
  },
};

const OrgSettings = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const [organizationName, setOrganizationName] = useState(
    user?.organization_name,
  );
  const [loading, setLoading] = useState(false);
  const onUpdateClick = useCallback(() => {
    setLoading(true);
    updateOrgName(organizationName as string)
      .then(() => refreshUser())
      .finally(() => setLoading(false));
  }, [organizationName]);

  return (
    <Box sx={GRID_CONTAINER}>
      <Stack direction={'column'} spacing={2}>
        <Stack
          direction={'column'}
          spacing={0}
          justifyContent={'start'}
          alignItems={'start'}
        >
          <TextFieldTitle title="Organization name" />
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <TextField
              size="small"
              variant="filled"
              sx={TEXT_FIELD}
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
            <LoadingButton
              loading={loading}
              onClick={onUpdateClick}
              disabled={organizationName === user?.organization_name}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack
          direction={'column'}
          spacing={0}
          justifyContent={'start'}
          alignItems={'start'}
        >
          <TextFieldTitle title="Pynt ID" />
          <CopyField
            title="﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡"
            sx={TEXT_FIELD}
            text={JSON.stringify({
              token: {
                access_token: getStoredAccessToken(),
                refresh_token: getStoredRefreshToken(),
                token_type: 'Bearer',
              },
            })}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default OrgSettings;
