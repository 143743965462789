import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import aws from '../assets/images/integrations/aws.png';
import azure from '../assets/images/integrations/azure.png';
import azureDevops from '../assets/images/integrations/azureDevops.png';
import gcp from '../assets/images/integrations/gcp.png';
import github from '../assets/images/integrations/github.png';
import gitlab from '../assets/images/integrations/gitlab.png';
import gravitee from '../assets/images/integrations/gravitee.png';
import jenkins from '../assets/images/integrations/jenkins.png';
import jira from '../assets/images/integrations/jira.png';
import kong from '../assets/images/integrations/kong.png';
import newman from '../assets/images/integrations/newman.png';
import postman from '../assets/images/integrations/postman.png';
import python from '../assets/images/integrations/python.png';
import restAssured from '../assets/images/integrations/restAssured.png';
import CustomCard from '../components/CustomCard/CustomCard';
import EditJiraIntegrationDrawer from '../components/Setup/EditJiraIntegrationDrawer';
import EditSourceDrawer from '../components/Setup/EditSource/EditSourceDrawer';
import { SourceData } from '../components/Setup/EditSource/EditSourceForm';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../contexts/Integrations';
import AlertDialog from '../dialogs/AlertDialog';
import { deleteIntegration } from '../services/IntegrationsService';

interface IntegrationItem {
  name: string;
  description: string;
  type?: string;
  image: string;
  link?: string;
  comingSoon?: boolean;
  connected?: boolean;
  integrationId?: string;
  onClick?: () => void;
}

interface IntegrationsCategory {
  name: string;
  integrations: IntegrationItem[];
}

const SELECT: SxProps = {
  '.MuiSelect-filled': {
    textAlign: 'start',
    backgroundColor: 'white',
    border: 'solid 1px #D3D3D3',
  },
};

const Integrations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { integrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [selectedIntegrationType, setSelectedIntegrationType] = useState(
    searchParams.get('type') || '*',
  );
  const [isEditSrouceDrawerOpen, setIsEditSrouceDrawerOpen] =
    useState<Partial<SourceData>>();

  const [isJiraDrawerOpen, setIsJiraDrawerOpen] =
    useState<Partial<{ code: string }>>();

  const integrationCategories: IntegrationsCategory[] = useMemo(() => {
    if (!integrations) return [];

    const categories: { name: string; integrations: IntegrationItem[] }[] = [
      {
        name: 'API Gateways',
        integrations: [
          {
            name: 'AWS',
            description: 'Monitor the risk of your API on AWS in production',
            image: aws,
            onClick: () => {
              setIsEditSrouceDrawerOpen({
                sourceOrigin: 'production',
                integration: 'apiGateway',
                vendor: 'aws',
              });
            },
          },
          {
            name: 'Azure',
            description: 'Monitor the risk of your API on Azure in production',
            image: azure,
            onClick: () => {
              setIsEditSrouceDrawerOpen({
                sourceOrigin: 'production',
                integration: 'apiGateway',
                vendor: 'azure',
              });
            },
          },
          {
            name: 'Kong',
            description: 'Monitor the risk of your API on Kong in production',
            image: kong,
            onClick: () => {
              setIsEditSrouceDrawerOpen({
                sourceOrigin: 'production',
                integration: 'apiGateway',
                vendor: 'kong',
              });
            },
          },
          {
            name: 'GCP',
            comingSoon: true,
            description: 'Monitor the risk of your API on GCP in production',
            image: gcp,
          },

          {
            name: 'Gravitee',
            comingSoon: true,
            description:
              'Monitor the risk of your API on Gravitee in production',
            image: gravitee,
          },
        ],
      },
      {
        name: 'API Testing',
        integrations: [
          {
            name: 'Postman',
            image: postman,
            link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-postman',
            description: 'Generate automated API security tests from Postman',
          },
          {
            name: 'Newman',
            image: newman,
            link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-newman',
            description: 'Generate automated API security tests from Newman',
          },
          {
            name: 'Python',
            image: python,
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/pytest',
            description:
              'Generate automated API security tests from Python API',
          },
          {
            name: 'Rest Assured',
            image: restAssured,
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/rest-assured',
            description:
              'Generate automated API security tests from Rest Assured',
          },
        ],
      },
      {
        name: 'CI/CD Pipelines',
        integrations: [
          {
            image: github,
            name: 'GitHub Actions',
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-on-ci-cd/github-actions',
            description:
              'Integrate Pynt’s API security tests into your GitHub pipeline',
          },
          {
            image: gitlab,
            name: 'GitLab',
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-on-ci-cd/gitlab',
            description:
              'Integrate Pynt’s API security tests into your GitLab pipeline',
          },
          {
            image: jenkins,
            name: 'Jenkins',
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-on-ci-cd/jenkins',
            description:
              'Integrate Pynt’s API security tests into your Jenkins pipeline',
          },
          {
            image: azureDevops,
            name: 'Azure DevOps',
            link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-on-ci-cd/azure-devops-pipelines',
            description:
              'Integrate Pynt’s API security tests into your Azure DevOps pipeline',
          },
        ],
      },
      {
        name: 'Ticketing Systems',
        integrations: [
          {
            image: jira,
            name: 'Jira',
            type: 'jira',
            description: 'Create Jira tickets for API security issues',
            onClick: () => {
              window.location.href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=0rcilGcM493juYiVZWHbabP8gzvf7jbF&scope=read:me read:jira-work write:jira-work offline_access&redirect_uri=${encodeURIComponent('https://app.pynt.io/dashboard/settings/integrations?integration=jira')}&state=${'asdsadasd'}&response_type=code&prompt=consent`;
            },
          },
        ],
      },
    ];

    return categories.map((category) => ({
      ...category,
      integrations: category.integrations.map((integration) => {
        const inte = integrations.find(
          (i) =>
            i.integration_type === integration.type && i.status === 'ACTIVE',
        );
        return {
          ...integration,
          integrationId: inte?.integration_id,
          connected: !!inte,
        };
      }),
    }));
  }, [integrations]);

  useEffect(() => {
    const intergration = searchParams.get('integration');
    if (intergration === 'jira') {
      const code = searchParams.get('code');
      if (code) {
        setSearchParams((prev) => {
          prev.delete('code');
          prev.delete('state');
          return new URLSearchParams(prev);
        });
        setIsJiraDrawerOpen({
          code,
        });
      }
    }
  }, [searchParams]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedIntegrationType(event.target.value as string);
    setSearchParams((prev) => {
      if (!event.target.value || event.target.value === '*') {
        prev.delete('type');
      } else {
        prev.set('type', event.target.value);
      }
      return new URLSearchParams(prev);
    });
  };

  return (
    <>
      <EditSourceDrawer
        mode={'create'}
        showApplicationSelector
        defaultData={
          isEditSrouceDrawerOpen ? { ...isEditSrouceDrawerOpen } : undefined
        }
        isOpen={!!isEditSrouceDrawerOpen}
        setIsOpen={() => setIsEditSrouceDrawerOpen(undefined)}
      />
      <EditJiraIntegrationDrawer
        isOpen={!!isJiraDrawerOpen}
        setIsOpen={() => setIsJiraDrawerOpen(undefined)}
        mode={'create'}
        code={isJiraDrawerOpen?.code}
      />
      <Container disableGutters maxWidth={false}>
        <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 1 }}>
          <Grid item sm={12} md={12}>
            <Box sx={{ maxWidth: 220, mt: 1, mb: 2 }}>
              <FormControl fullWidth>
                <Select
                  value={selectedIntegrationType}
                  variant="filled"
                  onChange={handleChange}
                  size="small"
                  sx={SELECT}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: 4,
                      },
                    },
                  }}
                >
                  <MenuItem value={'*'}>All integrations</MenuItem>
                  {integrationCategories?.map(
                    (integrationsCategory: IntegrationsCategory) => (
                      <MenuItem
                        key={integrationsCategory.name}
                        value={integrationsCategory.name}
                      >
                        {integrationsCategory.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {(selectedIntegrationType === '*'
            ? integrationCategories
            : integrationCategories.filter(
                (i: IntegrationsCategory) => i.name === selectedIntegrationType,
              )
          ).map((integrationCategory) => (
            <>
              {integrationCategory.integrations.map(
                (integration: IntegrationItem) => (
                  <Grid item key={integration.name} md={3} lg={2}>
                    <_IntegrationItem integration={integration} />
                  </Grid>
                ),
              )}
            </>
          ))}
        </Grid>
        {integrationCategories.length === 0 && (
          <Box mt={6}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
};

const _IntegrationItem = ({
  integration,
}: {
  integration: IntegrationItem;
}) => {
  const { fetchIntegrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [openDeleteIntegrationDialog, setOpenDeleteIntegrationDialog] =
    useState<boolean>(false);
  const [isDeletingIntegration, setIsDeletingIntegration] =
    useState<boolean>(false);

  const handleDeleteIntegration = useCallback(async () => {
    if (isDeletingIntegration) return;
    setIsDeletingIntegration(true);
    await deleteIntegration(integration.integrationId ?? '')
      .catch((e) => {
        console.error(e);
      })
      .then(async () => {
        await fetchIntegrations().catch((e) => {
          console.error(e);
        });
      })
      .finally(() => {
        setIsDeletingIntegration(false);
        setOpenDeleteIntegrationDialog(false);
      });
  }, []);

  return (
    <>
      <AlertDialog
        title="Are you sure want to discconect integration?"
        open={openDeleteIntegrationDialog}
        onClose={() => setOpenDeleteIntegrationDialog(false)}
        actions={[
          {
            title: 'Cancel',
            variant: 'outlined',
            onClick: () => {},
          },
          {
            title: 'Disconnect',
            variant: 'contained',
            loading: isDeletingIntegration,
            onClick: handleDeleteIntegration,
          },
        ]}
      />
      <CustomCard sx={{ border: '1px solid #D3D3D3' }}>
        <Stack
          direction={'column'}
          spacing={1.2}
          alignItems="center"
          justifyContent={'center'}
          sx={{ p: 1 }}
        >
          <img
            src={integration.image}
            height={56}
            width={56}
            style={{ objectFit: 'contain' }}
          />

          <Typography
            variant="subtitle1"
            textAlign="center"
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            width={'100%'}
            sx={{ fontSize: 18 }}
          >
            {integration.name}
          </Typography>

          <Box flex={1} />
          {integration.connected ? (
            <>
              {/* <Stack direction={'row'} gap={1} alignItems={'center'}>
            <CheckCircleFilledIcon size={16} />
            <Typography fontSize={14}>Connected</Typography>
          </Stack> */}
              <LoadingButton
                loading={isDeletingIntegration}
                variant="outlined"
                onClick={() => setOpenDeleteIntegrationDialog(true)}
              >
                {'Disconnect'}
              </LoadingButton>
            </>
          ) : (
            <Button
              disabled={integration.comingSoon}
              variant="contained"
              onClick={
                integration.onClick ??
                (() => {
                  if (integration.link) {
                    window.open(integration.link, '_blank');
                  }
                })
              }
            >
              {integration.comingSoon ? 'Coming Soon' : 'Connect'}
            </Button>
          )}
        </Stack>
      </CustomCard>
    </>
  );
};

export default Integrations;
