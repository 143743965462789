import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { createContext, useCallback, useEffect, useState } from 'react';
import {
  createPentestReport,
  getApplications,
  getPentestReportStatus,
} from '../services/ApplicationsService';
import { track } from '../utils/analytics';

const DOWNLOAD_BOX: SxProps = {
  p: 4,
  position: 'fixed',
  top: 16,
  right: 16,
  zIndex: 10000,
};

interface DownloadReportRequest {
  appId: string;
  scanId?: string;
  reqId?: string;
  url?: string;
  status: 'done' | 'failed' | 'pending';
}

export interface PentestReportDownloaderContextProps {
  downloading: boolean;
  request?: DownloadReportRequest;
  downloadReport: (appId: string) => Promise<any>;
}

export const PentestReportDownloaderContext =
  createContext<PentestReportDownloaderContextProps | null>(null);

function PentestReportDownloaderContextProvider(props: any) {
  const [downloading, setDownloading] = useState(false);
  const [request, setRequest] = useState<DownloadReportRequest | undefined>();

  const downloadReport = useCallback(
    async (appId: string) => {
      if (downloading) {
        throw new Error('Report is already being downloaded');
      }

      track('web_app_pentest_report_action_started', { appId });

      setDownloading(true);
      setRequest({ appId, status: 'pending' });

      try {
        const apps = await getApplications();
        const app = apps.find((a: any) => a.app_id === appId);

        const scanId = app.data_sources?.scan?.source_id;
        if (!scanId) {
          throw new Error('To create a report you first must run a scan');
        }

        setRequest({ appId, scanId, status: 'pending' });

        const res = await createPentestReport(appId, scanId);

        setRequest({ appId, scanId, reqId: res.pentest_id, status: 'pending' });

        return { ok: true };
      } catch (e: any) {
        setRequest(undefined);
        setDownloading(false);
        track('web_app_pentest_report_action_failed_to_start', {
          appId,
          error: e.message || e.toString(),
        });

        throw e;
      }
    },
    [downloading],
  );

  useEffect(() => {
    const status = request?.status;
    const reqId = request?.reqId;
    if (!reqId || status !== 'pending') return;

    const interval = setInterval(async () => {
      const { url, status } = await getPentestReportStatus(reqId).catch((e) => {
        setDownloading(false);
        setRequest((r) => (r ? { ...r, status: 'failed' } : undefined));
        track('web_app_pentest_report_action_failed', {
          appId: request.appId,
          error: e.message || e.toString(),
        });
        return {};
      });

      if (status === 'FAILED') {
        setDownloading(false);
        setRequest((r) => (r ? { ...r, status: 'failed' } : undefined));
        track('web_app_pentest_report_action_failed', {
          appId: request.appId,
          error: 'request changed to the failed status by the server',
        });
        return;
      }

      if (!url) return;

      setDownloading(false);
      setRequest((r) => (r ? { ...r, url, status: 'done' } : undefined));
      track('web_app_pentest_report_action_success', { appId: request.appId });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [request]);

  return (
    <PentestReportDownloaderContext.Provider
      value={{
        downloading,
        request,
        downloadReport,
      }}
    >
      {props.children}
      {request ? (
        <Paper elevation={3} sx={DOWNLOAD_BOX}>
          {request.status === 'pending' ? (
            <Stack direction={'row'} alignItems={'center'} gap={4}>
              <CircularProgress size={24} />
              <Stack>
                <Typography>Creating report</Typography>
                <Typography variant="body2">This may take some time</Typography>
              </Stack>
            </Stack>
          ) : (
            <></>
          )}
          {request.status === 'done' ? (
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Typography>Your report is ready!</Typography>
              <Button
                variant="contained"
                href={request.url ?? ''}
                download={'pynt-report.pdf'}
              >
                Download it Now
              </Button>
              <IconButton
                onClick={() => {
                  setRequest(undefined);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ) : (
            <></>
          )}
          {request.status === 'failed' ? (
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Typography color={'red'}>Failed creating report</Typography>
              <Button
                variant="contained"
                color="error"
                onClick={() => downloadReport(request.appId)}
              >
                Try Again
              </Button>
              <IconButton
                onClick={() => {
                  setRequest(undefined);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ) : (
            <></>
          )}
        </Paper>
      ) : (
        <></>
      )}
    </PentestReportDownloaderContext.Provider>
  );
}

export default PentestReportDownloaderContextProvider;
