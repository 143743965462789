import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Stack,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useNavigate } from 'react-router-dom';
import owaspIcon from '../../assets/images/owasp.png';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { AuthContext } from '../../contexts/Auth';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';
import { SnackbarContext } from '../../contexts/Snackbar';
import { Vulnerability, statusToTitle } from '../../models/vulnerability';
import {
  createTicket,
  getEvidence,
  getVulnerabilityById,
  updateVulneravilityFalsePositiveStatus,
} from '../../services/VulnerabilitiesService';
import { track } from '../../utils/analytics';
import {
  APIMethodChip,
  CustomDrawer,
  JiraTicketingBadge,
  Loader,
  TextFieldTitle,
} from '../Common';
import { JiraTicketForm } from '../Ticketing/JiraTicketForm';
import TestCategoryChip from './TestCategoryChip';

const GRAY_TEXT_BOX: SxProps = {
  bgcolor: '#F2F2F2',
  border: '1px solid #D3D3D3',
  p: 1.5,
  borderRadius: 0.5,
  fontSize: 12,
  lineHeight: 2,
  wordBreak: 'break-word',
  overflowX: 'scroll',
};

const BUTTONS_CONTAINER: SxProps = {
  borderTop: '1px solid #D3D3D3',
  pt: 2,
  px: 2,
};

const STEP_FORM_CONTAINER: SxProps = {
  py: 2,
  px: 3,
  pb: 2,
  mb: 4,
  bgcolor: '#0057FF0F',
  borderRadius: 1,
};

const TABS_UNDERLINE: SxProps = {
  mt: '-0.75px',
  mx: 2,
  height: '1px',
  width: 'calc(100%-16px)',
  bgcolor: '#D3D3D3',
};

const OVERVIEW_ITEM_TITLE: SxProps = {
  color: '#7B7B88',
  fontSize: 12,
};

interface Props {
  vulnerabilityId: string | undefined;
  vulnerability: Vulnerability | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onUpdated?: (vulnerability: Vulnerability) => void;
  hideEvidenceTab?: boolean;
}

const VulnerabilityDrawer = ({
  vulnerabilityId,
  vulnerability: vulnerabilityProp,
  isOpen,
  setIsOpen,
  onUpdated,
  hideEvidenceTab,
}: Props) => {
  const navigate = useNavigate();

  const { show } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const { integrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [vulnerability, setVulnerability] = useState<Vulnerability | undefined>(
    vulnerabilityProp,
  );
  const [isLoadingVulnerability, setIsLoadingVulnerability] = useState(false);
  const [errorLoadingVulnerability, setErrorLoadingVulnerability] =
    useState<string>();
  const [selectedTab, setSelectedTab] = useState({
    id: 'overview',
    title: 'Overview',
  });
  const [step, setStep] = useState<'mark-false-positive' | 'open-ticket'>();
  const [stepTouched, setStepTouched] = useState(false);
  const [stepData, setStepData] = useState<Record<string, any>>({});
  const [stepLoading, setStepLoading] = useState(false);

  const isAdmin = useMemo(() => user?.role === 'Admin', [user]);

  const jiraTicket = useMemo(() => {
    return vulnerability?.tickets?.find((t) => t.integration_type === 'jira');
  }, [vulnerability]);

  useEffect(() => {
    if (!vulnerabilityId) {
      setVulnerability(undefined);
      return;
    }

    setVulnerability(vulnerabilityProp);
    fetchVulnerability();
  }, [vulnerabilityProp, vulnerabilityId]);

  const tabs = useMemo(() => {
    return [
      { id: 'overview', title: 'Overview' },
      ...(vulnerability?.evidences?.length && !hideEvidenceTab
        ? [{ id: 'evidence', title: 'Evidence' }]
        : []),
      ...(vulnerability?.curl_cmd
        ? [{ id: 'reproduce', title: 'Reproduce via Curl' }]
        : []),
    ];
  }, [vulnerability, hideEvidenceTab]);

  const hasJiraIntegration = useMemo(() => {
    return (
      integrations?.some(
        (i) => i.status === 'ACTIVE' && i.integration_type === 'jira',
      ) ?? true
    );
  }, [integrations]);

  useEffect(() => {
    if (isOpen) return;

    setSelectedTab(tabs[0]);
    setStep(undefined);
    setStepTouched(false);
  }, [isOpen, tabs]);

  const onMarkFalsePositiveClick = useCallback(() => {
    setStepData({});
    setStep('mark-false-positive');
    setStepTouched(true);
  }, []);

  const onOpenTicketClick = useCallback(() => {
    setStepData({});
    setStep('open-ticket');
    setStepTouched(true);
  }, []);

  const fetchVulnerability = useCallback(async () => {
    setErrorLoadingVulnerability(undefined);
    setIsLoadingVulnerability(true);

    const vulnerability = await getVulnerabilityById(vulnerabilityId ?? '')
      .catch((e) => {
        setErrorLoadingVulnerability(e.message);
        return null;
      })
      .finally(() => {
        setIsLoadingVulnerability(false);
      });

    if (vulnerability === null) return;

    setVulnerability(vulnerability);

    return vulnerability;
  }, [vulnerabilityId]);

  const onSubmitFalsePositive = useCallback(() => {
    if (!vulnerability) return;

    track(
      'web_app_vulnerability_drawer_mark_false_positive_submit_button_click',
      {
        vulnerabilityId: vulnerabilityId,
        markAs:
          vulnerability.status === 'detected' ? 'false_positive' : 'detected',
      },
    );
    setStepLoading(true);

    const markFalsePositive = vulnerability.status === 'detected';
    updateVulneravilityFalsePositiveStatus(
      vulnerabilityId ?? '',
      markFalsePositive,
    )
      .then(() => {
        if (isAdmin) {
          if (markFalsePositive) {
            show('Vulnerability marked as false positive', 'success');
          } else {
            show('Vulnerability marked as not false positive', 'success');
          }
        } else {
          show('Request to mark vulnerability as false positive sent', 'info');
        }

        onUpdated?.(vulnerability);
        fetchVulnerability();
        setStep(undefined);
      })
      .catch((e) => {
        show('Failed to mark vulnerability as false positive', 'error');
      })
      .finally(() => {
        setStepLoading(false);
      });
  }, [vulnerability, vulnerabilityId, isAdmin]);

  const onSubmitCreateTicket = useCallback(() => {
    if (!vulnerability) return;

    const jiraIntegration = integrations?.find(
      (i) => i.integration_type === 'jira',
    );
    if (!jiraIntegration) return;

    track('web_app_vulnerability_drawer_create_ticket_submit_button_click', {
      vulnerabilityId: vulnerabilityId,
    });
    setStepLoading(true);

    createTicket(
      vulnerabilityId ?? '',
      jiraIntegration.integration_id,
      stepData,
    )
      .then(async () => {
        show('Ticket created', 'success');

        fetchVulnerability();
        onUpdated?.(vulnerability);
        setStepData({});
        setStep(undefined);
      })
      .catch((e) => {
        show('Failed to create ticket, ' + e.message, 'error');
      })
      .finally(() => {
        setStepLoading(false);
      });
  }, [vulnerability, integrations, vulnerabilityId, isAdmin, stepData]);

  return (
    <CustomDrawer
      sx={{
        width: step !== undefined ? '1001px' : '501px',
        transition:
          isOpen && stepTouched
            ? 'width 400ms ease-in-out, transform 2s cubic-bezier(0, 0, 0.2, 1) 0ms !important'
            : undefined,
      }}
      header={{
        title: vulnerability?.name,
        hideDivider: true,
      }}
      open={isOpen}
      setOpen={() => setIsOpen?.(false)}
    >
      {!vulnerability ? (
        <Stack alignItems={'center'}>
          {isLoadingVulnerability ? (
            <CircularProgress />
          ) : (
            <Typography color={'red'}>{errorLoadingVulnerability}</Typography>
          )}
        </Stack>
      ) : (
        <></>
      )}
      {vulnerability ? (
        <Stack overflow={'hidden'} flex={1} spacing={2} mx={-2}>
          <Stack direction={'row'} flex={1} overflow={'hidden'}>
            <Stack overflow={'hidden'} gap={2} flexShrink={0} width={500}>
              <Box>
                <Tabs
                  sx={{
                    px: 2,
                  }}
                  value={selectedTab.id}
                  onChange={(_, t) => {
                    if (selectedTab.id === t) return;
                    setSelectedTab(tabs.find((_t) => _t.id === t) ?? tabs[0]);
                  }}
                >
                  {tabs.map((t, i) => (
                    <Tab
                      sx={{ pl: 0, pr: 0, ml: i !== 0 ? 2 : 0 }}
                      key={t.id}
                      value={t.id}
                      label={t.title}
                    />
                  ))}
                </Tabs>
                <Box sx={TABS_UNDERLINE}></Box>
              </Box>
              {vulnerability ? (
                <Box flex={1} overflow={'scroll'} px={2}>
                  {selectedTab.id === 'overview' ? (
                    <VulnerabilityOverview
                      vulnerability={vulnerability}
                      onUpdated={(v) => {
                        fetchVulnerability();
                        onUpdated?.(v);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedTab.id === 'evidence' ? (
                    <VulnerabilityEvidence vulnerability={vulnerability} />
                  ) : (
                    <></>
                  )}
                  {selectedTab.id === 'reproduce' ? (
                    <ReproduceVulnerability vulnerability={vulnerability} />
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <Stack flex={1}>
                  <Loader />
                </Stack>
              )}
            </Stack>

            <Collapse
              key={'mark-false-positive-step'}
              in={step === 'mark-false-positive'}
              orientation="horizontal"
              collapsedSize={'500px'}
              hidden={step && step !== 'mark-false-positive'}
              sx={{
                width: '500px',
                opacity: step !== 'mark-false-positive' ? 0 : 1,
                transition: 'width, opacity 400ms ease-in-out',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <Stack
                width={'500px'}
                paddingInlineEnd={3}
                paddingInlineStart={2}
              >
                <Box sx={STEP_FORM_CONTAINER}>
                  <Stack gap={2}>
                    <Typography variant="h5" fontWeight={500}>
                      {vulnerability?.status === 'detected'
                        ? 'Mark Vulnerability as False Positive'
                        : 'Mark Vulnerability as not False Positive'}
                    </Typography>
                    <Box>
                      <TextFieldTitle title="Reason" />
                      <TextField
                        placeholder="Enter your reason for this change here..."
                        fullWidth
                        variant="filled"
                        size="small"
                        rows={5}
                        multiline
                        onChange={(v) => {}}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Collapse>

            <Collapse
              key={'open-ticket-step'}
              in={step === 'open-ticket'}
              hidden={step && step !== 'open-ticket'}
              orientation="horizontal"
              collapsedSize={'500px'}
              sx={{
                width: '500px',
                opacity: step !== 'open-ticket' ? 0 : 1,
                transition: 'width, opacity 400ms ease-in-out',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <Stack
                width={'500px'}
                paddingInlineEnd={3}
                paddingInlineStart={2}
              >
                <Box sx={STEP_FORM_CONTAINER}>
                  {vulnerabilityId ? (
                    <JiraTicketForm
                      vulnerabilityId={vulnerabilityId}
                      vulnerability={vulnerability}
                      disabled={stepLoading}
                      data={stepData}
                      onChange={setStepData}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Stack>
            </Collapse>
          </Stack>
          <Stack
            direction={'row'}
            gap={1}
            justifyContent={'end'}
            sx={BUTTONS_CONTAINER}
          >
            {step === 'mark-false-positive' ? (
              <>
                <Button
                  key={'cancel-mark-false-positive'}
                  variant="text"
                  disabled={stepLoading}
                  onClick={() => setStep(undefined)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  key={'submit-mark-false-positive'}
                  variant="contained"
                  loading={stepLoading}
                  onClick={() => onSubmitFalsePositive()}
                >
                  Submit
                </LoadingButton>
              </>
            ) : (
              <></>
            )}

            {step == 'open-ticket' ? (
              <>
                <Button
                  key={'cancel-open-ticket'}
                  variant="text"
                  disabled={stepLoading}
                  onClick={() => setStep(undefined)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  key={'submit-open-ticket'}
                  variant="contained"
                  loading={stepLoading}
                  onClick={() => onSubmitCreateTicket()}
                >
                  Save Ticket
                </LoadingButton>
              </>
            ) : (
              <></>
            )}

            {!step ? (
              <>
                {vulnerability?.owasp_link ? (
                  <Button
                    key={'owasp-link'}
                    variant="outlined"
                    startIcon={<img src={owaspIcon} height={20} width={20} />}
                    onClick={() => {
                      track(
                        'web_app_vulnerability_drawer_view_owasp_button_click',
                      );
                      window.open(vulnerability.owasp_link, '_blank');
                    }}
                  >
                    View OWASP
                  </Button>
                ) : (
                  <></>
                )}
                {!jiraTicket ? (
                  <Tooltip
                    title={
                      hasJiraIntegration ? (
                        'Open Jira Ticket'
                      ) : (
                        <Typography>
                          To open Jira tickets first add the Jira integration{' '}
                          <Button
                            sx={{ p: 0, minWidth: 0 }}
                            onClick={() => {
                              navigate(
                                '/dashboard/settings/integrations?type=Ticketing+Systems',
                              );
                            }}
                          >
                            here
                          </Button>
                          .
                        </Typography>
                      )
                    }
                  >
                    <Box>
                      <Button
                        key={'open-ticket'}
                        variant="contained"
                        disabled={!hasJiraIntegration}
                        onClick={() => onOpenTicketClick()}
                      >
                        Open Ticket
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <></>
                )}

                {(isAdmin &&
                  vulnerability?.status !== 'false_positive_claimed') ||
                vulnerability?.status === 'detected' ? (
                  <Tooltip
                    arrow
                    title={
                      vulnerability?.status === 'detected'
                        ? 'Mark the vulnerability as False Positive'
                        : 'Change the vulnerability status back to Detected'
                    }
                  >
                    <Button
                      key={'mark-false-positive'}
                      variant="contained"
                      sx={{ px: 1 }}
                      onClick={() => onMarkFalsePositiveClick()}
                    >
                      {vulnerability?.status === 'detected'
                        ? 'Mark as False Positive'
                        : 'Mark as Valid'}
                    </Button>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </CustomDrawer>
  );
};

export default VulnerabilityDrawer;

const VulnerabilityOverview = ({
  vulnerability,
  onUpdated,
}: {
  vulnerability: Vulnerability;
  onUpdated: ((vulnerability: Vulnerability) => void) | undefined;
}) => {
  const { user } = useContext(AuthContext);
  const isAdmin = useMemo(() => user?.role === 'Admin', [user]);

  const jiraTicket = useMemo(() => {
    return vulnerability.tickets?.find((t) => t.integration_type === 'jira');
  }, [vulnerability]);

  return (
    <Stack gap={2}>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={OVERVIEW_ITEM_TITLE}>Status</Typography>
        <Typography color="#ACACBB" fontSize={12}>
          {statusToTitle(vulnerability?.status)}
        </Typography>
      </Stack>

      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography flexShrink={0} sx={OVERVIEW_ITEM_TITLE}>
          Category
        </Typography>
        {vulnerability ? (
          <TestCategoryChip
            category={vulnerability.owasp_name ?? vulnerability.category}
          />
        ) : (
          <></>
        )}
      </Stack>

      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={OVERVIEW_ITEM_TITLE}>API</Typography>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Box>
            <APIMethodChip
              method={vulnerability?.endpoint?.split(' ')?.[0] ?? 'get'}
              grayed
            />
          </Box>
          <Typography
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            color="#050D21"
            fontSize={12}
          >
            {vulnerability?.endpoint?.split(' ')?.[1]}
          </Typography>
        </Stack>
      </Stack>

      {jiraTicket ? (
        <Stack
          direction={'row'}
          gap={1}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography sx={OVERVIEW_ITEM_TITLE}>Ticket</Typography>
          <JiraTicketingBadge ticket={jiraTicket} />
        </Stack>
      ) : (
        <></>
      )}

      <Stack gap={0.5}>
        <Typography>Description (What we found?)</Typography>
        <Typography sx={GRAY_TEXT_BOX}>{vulnerability.description}</Typography>
      </Stack>

      <Stack gap={0.5}>
        <Typography>Remediation (How to fix it)</Typography>
        <Typography sx={GRAY_TEXT_BOX}>{vulnerability.remediation}</Typography>
      </Stack>

      {vulnerability.status === 'false_positive_claimed' && isAdmin ? (
        <FalsePositiveApprovalCard
          vulnerability={vulnerability}
          onUpdated={onUpdated}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

const VulnerabilityEvidence = ({
  vulnerability,
}: {
  vulnerability: Vulnerability;
}) => {
  const { application } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;
  const [evidence, setEvidence] = useState<any[]>();
  const [error, setError] = useState();

  useEffect(() => {
    Promise.allSettled(
      vulnerability.evidences.map((e) =>
        getEvidence(application?.data_sources.scan?.source_id ?? '', e),
      ),
    )
      .then((res) => {
        if (!res.some((a) => a.status === 'fulfilled')) {
          throw (res[0] as any).reason;
        }
        setEvidence(
          res
            .map((e) => e.status === 'fulfilled' && e.value)
            .filter((a) => !!a),
        );
      })
      .catch((e) => setError(e || 'failed to get evidence'));
  }, [vulnerability]);

  if (!evidence?.length) {
    if (error) {
      return (
        <Stack alignItems={'center'}>
          <Typography color={'red'}>Error getting evidence</Typography>
        </Stack>
      );
    }
    return (
      <Stack alignItems={'center'}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Typography>Evidence</Typography>
        <Box sx={GRAY_TEXT_BOX}>
          <React.Fragment>
            <JsonView
              data={evidence}
              shouldExpandNode={allExpanded}
              style={{ ...defaultStyles, container: '' }}
            />
          </React.Fragment>
        </Box>
        {/* <Typography sx={GRAY_TEXT_BOX}>
          <pre>
            {evidence.map((e) => JSON.stringify(e, null, 2)).join('\n\n')}
          </pre>
        </Typography> */}
      </Stack>
    </Stack>
  );
};

const ReproduceVulnerability = ({
  vulnerability,
}: {
  vulnerability: Vulnerability;
}) => {
  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Typography>Reproduce via Curl</Typography>
        <Typography sx={GRAY_TEXT_BOX}>{vulnerability.curl_cmd}</Typography>
      </Stack>
    </Stack>
  );
};

const FalsePositiveApprovalCard = ({
  vulnerability,
  onUpdated,
}: {
  vulnerability: Vulnerability;
  onUpdated: ((vulnerability: Vulnerability) => void) | undefined;
}) => {
  const { show } = useContext(SnackbarContext);
  const [loading, setLoading] = useState<{ accept: boolean }>();

  const onSubmitFalsePositive = useCallback(
    (accept: boolean) => {
      track(
        'web_app_vulnerability_drawer_mark_false_positive_approve_reject_button_click',
        {
          accept,
        },
      );
      setLoading({ accept });

      updateVulneravilityFalsePositiveStatus((vulnerability as any).id, accept)
        .then(() => {
          if (accept) {
            show('Vulnerability marked as false positive', 'success');
          } else {
            show(
              'Request to mark vulnerability as false positive rejected',
              'info',
            );
          }

          onUpdated?.(vulnerability);
        })
        .catch((e) => {
          show('Failed to update vulnerability', 'error');
        })
        .finally(() => {
          setLoading(undefined);
        });
    },
    [vulnerability, show],
  );

  return (
    <Box sx={STEP_FORM_CONTAINER}>
      <Stack gap={2}>
        <Typography variant="h6" fontWeight={500}>
          Vulnerability was marked as false positive
        </Typography>
        {/* <Box>
          <TextFieldTitle title="Why" />

          <TextField
            fullWidth
            variant="filled"
            size="small"
            rows={5}
            value={'This is why I marked it as false positive'}
            InputProps={{
              readOnly: true,
            }}
            multiline
          />
        </Box> */}

        <Stack direction={'row'} gap={1} justifyContent={'end'}>
          <LoadingButton
            variant="outlined"
            disabled={!!loading}
            loading={loading && !loading.accept}
            onClick={() => onSubmitFalsePositive(false)}
          >
            Reject
          </LoadingButton>
          <LoadingButton
            variant="contained"
            disabled={!!loading}
            loading={loading && loading.accept}
            onClick={() => onSubmitFalsePositive(true)}
          >
            Approve
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};
