import { PyntFilter } from './ApplicationsService';
import { get, post } from './BaseService';

export async function getScanById(scanId: string) {
  const res = await get('/scan', {
    params: {
      scan_id: scanId,
    },
  });

  return res.data;
}

export async function getScans(filter: PyntFilter = {}) {
  const res = await get('/scan/summary', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}

export async function getScansCount(where: PyntFilter['where'] = {}) {
  const res = await get('/scan/summary/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getAppScans(appId: string) {
  const res = await get('/scan?app_id=' + appId);

  return res.data;
}

export async function getReportHTML(scanId: string) {
  const res = await get('/report', {
    params: { scan_id: scanId },
  });

  return res.data;
}

export async function connectApplicationToScan(
  scanId: string,
  applicationId: string,
) {
  const res = await post('/group', {
    type: 'scan',
    value: scanId,
    application: applicationId,
  });

  return res.data;
}

export const downloadReport = async (scanId: string) => {
  try {
    // const response = await get('/report', {
    //   params: { scan_id: scanId },
    // });
    // Making a GET request using axios
    const response = await get('/report', {
      responseType: 'blob', // Important to handle the response as a Blob
      params: { scan_id: scanId },
    });

    // Creating a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Creating a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.html'); // Setting the file name for download

    // Appending to the DOM and triggering the download
    document.body.appendChild(link);
    link.click();

    // Cleaning up by removing the link and revoking the blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file: ', error);
  }
};

export async function runPostmanScanOnCloud(
  appId: string,
  apiKey: string,
  collectionUid: string,
  environmentUid?: string,
) {
  const res = await post('/runscan', {
    apiKey,
    collectionToTest: collectionUid,
    environmentName: environmentUid,
    application_id: appId,
  });

  return res.data;
}
