import { Container } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import APICatalogGrid from '../components/APICatalog/APICatalogGrid';

import {
  DashboardAppBar,
  GridWrapper,
  SelectApplication,
} from '../components/Common';
import { UserContext, UserContextProps } from '../contexts/User';

const Catalog = () => {
  const [searchParams] = useSearchParams();
  const { selectedApplicationId, setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  const filter = useMemo(() => {
    const val = searchParams.get('filter');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  const sorting = useMemo(() => {
    const val = searchParams.get('sorting');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  return (
    <>
      <DashboardAppBar
        title={'APIs'}
        startActions={
          <SelectApplication
            selectedApplicationId={selectedApplicationId}
            setSelectedApplicationId={setSelectedApplicationId}
          />
        }
      />
      <Container maxWidth={false}>
        <GridWrapper>
          <APICatalogGrid
            id="APICatalogGridInCatalogScreen"
            autoHeight={false}
            filter={filter}
            sorting={
              sorting
                ? [...sorting, { field: 'properties.scan_risk', sort: 'desc' }]
                : [{ field: 'properties.scan_risk', sort: 'desc' }]
            }
            columnVisibilityModel={{ application: true }}
            pyntFilter={
              selectedApplicationId === '*'
                ? {}
                : { application: selectedApplicationId }
            }
          />
        </GridWrapper>
      </Container>
    </>
  );
};

export default Catalog;
