import Hotjar from '@hotjar/browser';
import { User } from '../models/user';

export const updateHotjar = async (user?: User) => {
  if (user != null) {
    if (Hotjar.isReady()) {
      Hotjar.identify(user.user_id, {
        email: user.email,
        name: [user.name, user.lastname].filter((x) => !!x).join(' '),
        organizationId: user.organization ?? '',
        organizationName: user.organization_name ?? '',
        createdAt: user.created_at,
        // avatar: user.photoURL,
      });
    }
  }
};
