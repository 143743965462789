import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import postmanSetupExplainerImage1 from '../../assets/images/postmanSetupExplainerImage1.png';
import postmanSetupExplainerImage2 from '../../assets/images/postmanSetupExplainerImage2.png';
import postmanSetupExplainerImage3 from '../../assets/images/postmanSetupExplainerImage3.png';
import postmanSetupExplainerImage4 from '../../assets/images/postmanSetupExplainerImage4.png';
import { track } from '../../utils/analytics';

const CONTAINER: SxProps = {
  // borderRadius: '30px',
  // border: '4px solid rgba(255, 255, 255, 0.14)',
  // boxShadow: '0px 4px 40px 0px rgba(7, 13, 32, 0.60)',
  zIndex: 10100000,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  border: '4px solid rgba(255, 255, 255, 0.14)',
  boxShadow: '0px 4px 40px rgba(7, 13, 32, 0.6)',
  borderRadius: '30px',
  p: 7,
  maxHeight: '90vh',
  overflow: 'scroll',
};

const MODEL_STYLE: SxProps = {
  height: '100%',
  width: '100%',
  position: 'fixed',
  background: 'rgb(0 0 0 / 40%)',
  backdropFilter: 'blur(10px)',
};

const CLOSE_BUTTON: SxProps = {
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'white',
};

const OPTION_BUTTON: SxProps = {
  color: 'white',
  borderColor: 'white',
  textAlign: 'center',
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '-0.187px',
  whiteSpace: 'nowrap',
};

const SELECTED_OPTION_BUTTON: SxProps = {
  color: 'black',
  backgroundColor: 'white',
  textAlign: 'center',
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '-0.187px',
  whiteSpace: 'nowrap',
};

const TEXT: SxProps = {
  color: 'white',
  textAlign: 'center',
  // eslint-disable-next-line quotes
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '-0.187px',
};

const OPTIONS: { event: string; title: string; child: ReactElement }[] = [
  {
    event: 'onboarding-2-about-what-is-pynt',
    title: 'What is Pynt?',
    child: (
      <>
        <Typography sx={TEXT}>
          Pynt is an API security solution, which generates automated security
          tests in Postman based on your existing functional tests.
          <br />
          Pynt’s dynamic security testing covers all the OWASP API Top 10
          categories, retrieving results about your overall security in just a
          few minutes.
        </Typography>
        <img
          src={postmanSetupExplainerImage1}
          style={{ height: '350px', alignSelf: 'center', maxWidth: '100%' }}
        />
      </>
    ),
  },
  {
    event: 'onboarding-2-about-why-api-key',
    title: 'Why do you need my API key?',
    child: (
      <>
        <Typography sx={TEXT}>
          <strong>
            As Pynt generates automated security tests for your Postman
            functional test collection, we ask for your Postman API key.
          </strong>
          <br />
          Once you provide your Postman API key, we will fork Pynt collection
          into your chosen workspace, and you will be able to run Pynt
          collection from your environment.
        </Typography>
        <img
          src={postmanSetupExplainerImage2}
          style={{ height: '350px', alignSelf: 'center', maxWidth: '100%' }}
        />
      </>
    ),
  },
  {
    event: 'onboarding-2-about-what-will-happen',
    title: 'What will happen?',
    child: (
      <>
        <Typography sx={TEXT}>
          As Pynt generates automated security tests for your Postman functional
          test collection, we ask for your Postman API key.
          <br />
          Once you provide your Postman API key, we will fork Pynt collection
          into your chosen workspace, and you will be able to run Pynt
          collection from your environment.
        </Typography>
        <img
          src={postmanSetupExplainerImage3}
          style={{ height: '350px', alignSelf: 'center', maxWidth: '100%' }}
        />
      </>
    ),
  },
  {
    event: 'onboarding-2-about-whats-next',
    // eslint-disable-next-line quotes
    title: "What's next?",
    child: (
      <>
        <Typography sx={TEXT}>
          To run Pynt on your functional test collection for finding security
          issues, you will need to modify ‘your-collection’ variable of Pynt’s
          collection to your functional test collection. <br />
          Please proceed with Pynt collection documentation in your workspace.
          <br />
          <br />
        </Typography>
        <img
          src={postmanSetupExplainerImage4}
          style={{ height: '350px', alignSelf: 'center', maxWidth: '100%' }}
        />
      </>
    ),
  },
];

interface Props {
  open: boolean;
  onClose: () => void;
}

const PostmanOnboardExplainModal = ({ open, onClose }: Props) => {
  const [selected, setSelected] = useState(0);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={MODEL_STYLE}
      slots={{
        backdrop: () => <></>,
      }}
    >
      <Box sx={CONTAINER} className="hide-scrollbars">
        <IconButton sx={CLOSE_BUTTON} onClick={() => onClose()}>
          <Close />
        </IconButton>
        <Stack
          direction={'column'}
          justifyContent={'stretch'}
          width={'50vw'}
          maxWidth={'1200px'}
          minWidth={'400px'}
          spacing={6}
          overflow={'scroll'}
        >
          <Stack gap={2} direction={'row'} flexWrap={'wrap'} rowGap={1}>
            {OPTIONS.map((option, index) => (
              <Button
                key={option.title}
                variant={index === selected ? 'contained' : 'outlined'}
                onClick={() => {
                  track(option.event);
                  setSelected(index);
                }}
                sx={index === selected ? SELECTED_OPTION_BUTTON : OPTION_BUTTON}
              >
                {option.title}
              </Button>
            ))}
          </Stack>
          {OPTIONS[selected]?.child ?? <></>}
        </Stack>
      </Box>
    </Modal>
  );
};

export default PostmanOnboardExplainModal;
