import { createContext, useMemo, useState } from 'react';
import { getInvites, getOrgUsers } from '../services/OrgUsersService';

export interface OrgUser {
  name: string;
  lastname: string;
  user_id?: string;
  role?: string;
}

export interface OrgUserInvite {
  invite_id?: string;
  email?: string;
  role?: string;
  status: 'accepted' | 'pending';
}

export interface OrganizationContextProps {
  isLoadingUsers: boolean;
  users?: OrgUser[];
  fetchUsers: () => Promise<OrgUser[]>;

  isLoadingInvites: boolean;
  invites?: OrgUserInvite[];
  fetchInvites: () => Promise<OrgUserInvite[]>;
}

export const OrganizationContext =
  createContext<OrganizationContextProps | null>(null);

function OrganizationContextProvider(props: any) {
  const [users, setUsers] = useState<OrgUser[]>();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const [invites, setInvites] = useState<OrgUserInvite[]>();
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);

  const fetchUsers = useMemo(
    () => async () => {
      if (isLoadingUsers) return;

      setIsLoadingUsers(true);

      const apiSources = await getOrgUsers()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingUsers(false);
        });
      setUsers(apiSources);

      return apiSources;
    },
    [isLoadingUsers],
  );

  const fetchInvites = useMemo(
    () => async () => {
      if (isLoadingInvites) return;

      setIsLoadingInvites(true);

      const invites = await getInvites()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingInvites(false);
        });
      setInvites(invites || []);

      return invites;
    },
    [isLoadingUsers],
  );

  return (
    <OrganizationContext.Provider
      value={{
        isLoadingUsers,
        users,
        fetchUsers,

        isLoadingInvites,
        invites,
        fetchInvites,
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
}

export default OrganizationContextProvider;
